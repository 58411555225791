import React from "react";
import GalleryComponent from "./galleryComponent";
import AnimatedText from "./HeadingAnimation";

export default function Home() {

  return (
    <div className="  ">
      <div className="container  px-6 mx-auto mt-5 ">
        <div className="">
          <section className=" ">
            <div className="flex justify-center items-center">
              
            </div>
            <GalleryComponent />
          </section>
        </div>
      </div>
    </div>
  );
}
