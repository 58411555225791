import React from "react";
import Navbar from "./navbar";
import BottomBar from "./bottomBar";

export default function Termandcondition() {
  return (
    <>
    <Navbar/>
   
    <div className="container mx-auto leading-6 text-sm">
      <h1 className="text-blue-600 mt-4">bestpropertiesmohali.com</h1>
      <p>
        These terms of use ("Terms") constitute a legally binding agreement
        between you and bestpropertiesmohali Realty Services Limited (the "Company/ We/
        bestpropertiesmohali or Us") regarding the use of the web application/ website
        www.bestpropertiesmohali.com (the "Site") or the domain thereof and any service
        offered or deemed to be offered by the Company including but not limited
        to delivery of content via the Site, any mobile or internet connected
        device or otherwise (the "the Service").<br></br>
        Your use of the Site and services and tools are governed by the
        following Terms & Conditions which are incorporated herein. By mere use
        of the Site, you shall be contracting with bestpropertiesmohali Realty Services
        Limited, the owner of the Platform. These terms and conditions including
        the policies constitute your binding obligations, with bestpropertiesmohali.
        Therefore, it is imperative that before using the platform you acquaint
        yourself and understand the applicability and consequential application
        of these terms & conditions.<br></br>
        When You use any of the services provided by Us through the Platform,
        including but not limited to, (e.g. Product Reviews, Seller Reviews),
        You will be subject to the rules, guidelines, policies, terms, and
        conditions applicable to such service, and they shall be deemed to be
        incorporated into these Terms and shall be considered as part and parcel
        of these Terms.<br></br>
        bestpropertiesmohali may amend/modify these terms and conditions at any time, and
        such modifications shall be effective immediately upon posting of the
        modified terms and conditions on bestpropertiesmohali. You may review the
        modified terms and conditions periodically to be aware of such
        modifications and your continued access or use of bestpropertiesmohali, shall be
        deemed conclusive proof of your acceptance of these terms and
        conditions, as amended/modified from time to time. bestpropertiesmohali reserves
        the right to suspend the operations for support or technical
        upgradation, maintenance work, in order to update the content or for any
        other reason without intimating any user in advance.<br></br>
      </p>
      <ol type="I"></ol>
      <h5>
        <li>Defined Terms:</li>
      </h5>
      <p>
        Unless otherwise specified, the capitalized words shall have the
        meanings as defined herein below:
      </p>
      <ol type="1">
        <li>
          "Agreement" shall mean and include the completed application form, its
          attachment(s) and the terms and conditions stated herein. It shall be
          deemed to have been executed at New Delhi.
          <li>
            "Company": is defined as bestpropertiesmohali Realty Services Limited ("the
            Company") an existing Company under the Companies Act, 1956 and
            having its corporate office at Times Centre (Digital Content
            Production Facility), FC - 6, (Third Floor), Sector 16 A, Film City,
            NOIDA - 201301 U.P., along with its unit for the Company's website
            bestpropertiesmohali.com.{" "}
          </li>
          <li>
            "Date of Commencement" is the date indicating the acceptance of the
            application by the User to the service.{" "}
          </li>
          <li>
            "Date of Termination" is the date of expiry mentioned in the notice
            or/and the letter of termination and/or the date when the services
            are supposed to end.{" "}
          </li>
          <li>
            {" "}
            "bestpropertiesmohali.com" is defined as the internet website or mobile
            application of the Company at www.bestpropertiesmohali.com
          </li>
          <li>
            {" "}
            "My Subscriptions" contains time to time information and description
            of the Services for the User provided by the Company in writing or
            contained in the website bestpropertiesmohali.com.
          </li>
          <li>
            {" "}
            Registration Data" is the database of all the particulars and
            information supplied by the User on initial application and
            subscription, including but without limiting to the User's name,
            telephone number, mailing address, account and email address.
          </li>
          <li>
            {" "}
            "User" will includes everyone who is a Subscriber/Advertiser(s) and
            Browser/Visitor(s) either as an individual or corporate subscriber
            for the Services and the signatory, whose particulars are contained
            in the application form and includes his successors and permitted
            assignees. "User" or "You" also includes any person who access or
            avail the Services provided by the Company for the purpose of
            hosting, publishing, sharing, listing, transacting, viewing,
            displaying or uploading information or views and includes other
            persons jointly participating in using the Services provided by the
            Company.
          </li>
          <li>
            Words referring to masculine include the feminine and the singular
            include the plural and vice versa as the context admits or requires;
            and Words importing persons includes individuals, bodies corporate
            and unincorporated{" "}
          </li>
        </li>
      </ol>
      <h5>
        <li>Term:</li>
      </h5>
      <p>
        These Terms shall continue to form a valid and binding contract between
        the Parties and shall continue to be in full force and effect till the
        User continues to access the Site and avails the Services provided by
        the Company.
      </p>
      <h5>
        <li>Services:</li>
      </h5>
      <p>
        Company provides a number of internet-based services through its
        platform and shall include:
      </p>
      <ol type="1">
        <li>
          Posting User profile or listing for the purpose of sale/rental of
          property, and related property services etc.
        </li>
        <li>Find a property through bestpropertiesmohali.com and its internet links.</li>
        <li>
          Place a print advertisement in any of the group publications through
          the www.bestpropertiesmohali.com site.
        </li>
        <li>Post advertisements on bestpropertiesmohali.com.</li>
        <li>
          Send advertisements and promotional messages through emails and
          messages.
        </li>

        <p>
          The Services can be purchased through various methods of payments
          offered. The purchase of Services shall be additionally governed by
          specific policies of sale, like subscription fees, payment and Refund
          policy, cancellation policy etc.
        </p>
      </ol>
      <h5>
        <li>Eligibility:</li>
      </h5>
      <p>
        You hereby represent and warrant to the Company that you are at least
        eighteen (18) years of age or above and are competent to enter into a
        valid and binding contract apart from being capable of entering,
        performing and adhering to these Terms & Conditions. While individuals
        under the age of 18 may utilize the Service of the site, they shall do
        so only with the involvement & guidance of their parents and / or legal
        guardians, under such Parent /Legal guardian's registered account. You
        agree to register prior to uploading any content and / or comment and
        any other use or services of this site and provide your details
        including but not limited to complete name, age, email address,
        residential address, and contact number.
      </p>
      <h5>
        <li>PG Listing and PG Platform:</li>
      </h5>
      <ol type="1">
        <li>
          The User is solely and completely responsible to verify the accuracy,
          reliability and genuineness of the listing made on MB, MB is no way
          shall be whether directly or indirectly responsible for any fraud or
          cheating that may arise due to any fake or incorrect listing.
        </li>
        <li>
          That the package bought from MB can be used to post regular as well as
          PG listings on the current bestpropertiesmohali Platform only till the launch
          of the new PG platform. In this case, however, if the User has any
          portion remaining of his/her/its package, User can use it only in the
          current bestpropertiesmohali platform for listings other than PG.
        </li>
        <li>
          That all PG listings will be visible on the current platform. However,
          post launch of PG Platform, these listings would be migrated to the
          new platform and shall be visible till the expiry duration of the
          listings.
        </li>
        <li>
          That the User, who buys a separate PG pack, hereby agrees that in
          order to improve genuine listings and have authorised Sellers, the
          Company mandates the agent(s) posting listings against specific PGs to
          obtain NOC from the PG owners or Operators.
        </li>
        <li>
          PG owners or Operators shall be required to complete KYC as per
          bestpropertiesmohali policy.
        </li>
      </ol>
      <h5>
        <li>Subscription Fees</li>
      </h5>
      <ol type="1">
        <li>
          The applicable rate of the Subscription Fees for the Service provided
          shall be such as mentioned in the "My Subscriptions" page or as may be
          prescribed by the Company from time to time.
        </li>
        <li>
          Liability for the Subscription Fees shall accrue from the Date of
          Commencement.
        </li>
        <li>
          All individual Users who access or make posting of information on the
          Site for the purpose of buying property shall be exempted from the
          application of this clause unless there is a change in policy as per
          discretion of the Company.
        </li>
      </ol>
      <h5>
        <li>Payment & Refund clause</li>
      </h5>
      <ol type="1">
        <li>
          For all services bought, 50% of the order amount would be towards the
          activation/administration fees & the rest 50% would be refunded on
          pro-rata basis in case of cancellation considering the usages of the
          services. Customer agrees that the refund process may take around 4 to
          6 weeks after the complete documentation has been received by the
          Finance team of bestpropertiesmohali for processing such refund. It is made
          clear that all refunds are subject to discretion of bestpropertiesmohali and
          cannot be claimed as a matter of right.
        </li>
        <li>
          Where Subscription Fees accrues it shall be payable at or within such
          time as stated in the invoice(s) issued by the Company to the User.
        </li>
        <li>
          The Subscription Fees shall be paid by the User on demand. In case the
          user disputes the same for any reason whatsoever, he shall make the
          payment towards the Subscription Fees accrued subject to the decision
          of the Company on the dispute. In the event of Company's deciding the
          dispute in the User's favour, the Company shall refund to the User any
          excess amount paid by the User without any interest.
        </li>

        <li>
          Any delay in the payment by the User of any sums due under this
          Agreement, the Company shall have the right to charge interest on the
          outstanding amount from the date the payment became due until the date
          of final payment by the User.
        </li>

        <li>
          Payments made to the Company through IOS app shall not be refundable
          under any circumstances, including but not limited to the termination
          of this Agreement for whatever reason.
        </li>
      </ol>
      <ul>
        <h5>Owner’s Services:</h5>
      </ul>
      <ol type="1">
        <li>
          For all services bought, 50% of the order amount would be towards the
          activation/administration fees & the rest 50% would be refunded on
          pro-rata basis in case of cancellation considering the usages of the
          services. Customer agrees that the refund process may take around 4 to
          6 weeks after the complete documentation has been received by the
          Finance team of bestpropertiesmohali for processing such refund. It is made
          clear that all refunds are subject to discretion of bestpropertiesmohali and
          cannot be claimed as a matter of right.
        </li>
        <li>
          Where Subscription Fees accrues it shall be payable at or within such
          time as stated in the invoice(s) issued by the Company to the User.
        </li>
      </ol>
      <h5>
        <li>Charge Back Policy</li>
      </h5>
      <ol type="1">
        <li>
          Payment for the services offered shall be on 100% advance basis.
        </li>
        <li>
          Payment for service once subscribed to by the subscriber, is not
          refundable and any amount paid shall stand appropriated.
        </li>

        <li>
          Refund if any will be at the sole discretion of the Company only.
        </li>
        <li>
          User acknowledges and agrees that the Company at its sole discretion
          and without prejudice to other rights and remedies that it may have
          under the applicable laws, shall be entitled to set off the amount
          paid by a subscriber/user, against any amount(s) payable by user to
          the Company under any other agreement or commercial relationship
          towards other products/services.
        </li>
        <li>
          The Company offers no guarantees whatsoever for the accuracy or
          timeliness of the refunds reaching the Subscribers card/bank accounts.
          This is on account of the multiplicity of organizations involved in
          processing of online transactions, the problems with Internet
          infrastructure currently available and working days/holidays of
          financial institutions.
        </li>
      </ol>
      <h5>
        <li>Cancellation</li>
      </h5>
      <ol type="1">
        <li>
          Company reserves the exclusive right to cancel/ stop any content
          whatsoever from being published or reflected on its website or in any
          other mode. The cancellation charges payable to the User shall be at
          the applicable rates laid down in the cancellation and refund policy.
        </li>
        <li>
          For Platinum listing packages, there shall be no cancellation or
          refund of orders booked/payments made via online payment options
          (except in the case of Cheque & Demand Draft). Cancellations requests
          for orders placed via cheque/demand draft can be made only before such
          payment is realized by the Company.
        </li>
      </ol>
      <h5>
        <li>SECURITY</li>
      </h5>
      <ol type="1">
        <li>
          Transactions on the Site are secure and protected. Any information
          entered by the User when transacting on the Site is encrypted to
          protect the User against unintentional disclosure to third parties.
          The User's credit and debit card information is not received, stored
          by or retained by the Company / Site in any manner. This information
          is supplied by the User directly to the relevant payment gateway which
          is authorized to handle the information provided, and is compliant
          with the regulations and requirements of various banks and
          institutions and payment franchisees that it is associated with.
        </li>
        <li>
          To protect the secrecy of user identification and/or password the User
          shall take all such measures as may be necessary (including but
          without limiting to changing his password from time to time and shall
          not reveal the same to any other person(s).
        </li>

        <li>
          Since a user identification is necessary to access the Service; the
          User shall use only his own user identification.
        </li>

        <li>
          It is agreed by the User that he acquires no rights to any mailbox
          number or/and the User identification or/and circuit reference or/and
          any codes assigned to him by the Company. The User further agrees that
          except as otherwise proved herein, the Company reserves the right to
          change or/and re-assign the same to the User, at its sole discretion
          without being liable to the User for any kind of damages or/and relief
          or/and any other consequence/s.
        </li>

        <li>
          In the event of theft or/and loss of User identification or/and
          password or/and security word, the User shall notify the Company
          immediately via telephone or/and concurrently in person provide the
          Company with a written notice to the same effect. The User shall
          remain liable for use of the Services by any third party until such
          theft or loss is notified to the Company.
        </li>

        <li>
          The password and username being made available to the customer shall
          be used only by the organization named by the customer and the
          employee of the organization. The User shall take all necessary
          pre-cautions to prevent un-authorized access or/and leakage of
          username or/and password being provided by the Company to him.
        </li>

        <li>
          The User shall not use any software to automatically download or/and
          extract either a complete or/and partial listing from bestpropertiesmohali.com
          database without prior consent from bestpropertiesmohali in writing.
        </li>

        <li>
          bestpropertiesmohali shall in no way be directly, indirectly or remotely liable
          for any fraudulent transaction or deduction of money from the bank
          account of any User.
        </li>
      </ol>
      <h5>
        <li>Obligations and Representations of User/Subscriber</li>
      </h5>
      <ol type="1">
        <li>
          To provide accurate, complete and correct registration data on initial
          application for the Services.
        </li>
        <li>
          The User agrees that any data entered on the Site will be subject to
          mandatory verification process by the Company.
        </li>

        <li>
          Any and all licenses, permits, consents, approvals and intellectual
          property or other rights as may be required for using the Service
          shall be obtained by the User at his own cost.
        </li>

        <li>
          The User will ensure compliance with all notices or instructions given
          by the Company from time to time to enable the use of the Service.
        </li>

        <li>
          The User understands and agrees that the User is responsible for all
          applicable taxes and for all costs that are incurred in using the Site
          service(s).
        </li>

        <li>
          The User shall be solely responsible for all information retrieved,
          stored and transmitted by him.
        </li>

        <li>
          The User shall keep confidential and not disclose to any person the
          User's password and user identification and all activities and
          transmission performed by the User through his user identification.
        </li>

        <li>
          The User shall be responsible for all the activities that occur on
          his/its display name and password.
        </li>

        <li>
          The User agrees to immediately notify the Company of any unauthorized
          use / breach of his/its password or account and ensure to exit from
          the account at the end of each session.
        </li>

        <li>
          The User shall immediately notify the Company of any un-authorized use
          of the User's account or any other breach of security known to the
          User.
        </li>

        <li>
          The User shall promptly make the payment to the Company towards the
          Subscription Fees as and when it becomes payable.
        </li>

        <li>
          To ensure that he/she is above 18 years in age and competent to enter
          into the contract.
        </li>

        <li>
          The User shall be responsible for the set-up or configuration of his
          equipment for access to the Services.
        </li>

        <li>
          User hereby declares that he/it is fully aware that the online
          advertisements placed with bestpropertiesmohali.com shall be reflected after 48
          hours and agrees to the stipulated 48 hours processing.
        </li>

        <li>
          The User agrees that any data entered into herein can and shall be
          saved, used and commercially exploited by bestpropertiesmohali Realty Services
          Limited as deemed fit by them.
        </li>

        <li>
          The User shall give out to the Company a non-exclusive, worldwide,
          royalty-free, irrevocable, sub-licensable right (through multiple
          tiers) to exercise all copyright and publicity rights, in any existing
          or future media, known or unknown, over the material or User Data
          displayed in your listings. For the purpose of this Agreement, "User
          Data" shall mean all information (if any) submitted by the User on
          bestpropertiesmohali.com with the exception of trading data, credit card
          numbers, checking account numbers, etc. "Individually Identifiable
          User Data" shall mean that subset of "User Data" which can be
          reasonably used to identify a specific individual such as their name,
          address, phone number, etc. The User Data shall be deemed to be the
          property of bestpropertiesmohali.com. The User shall take all reasonable
          efforts to ensure that it is accurate and complete and not misleading
          in any manner.
        </li>

        <li>
          The User will indemnify bestpropertiesmohali Realty Services Limited for any
          action or claim committed/made by any third party resulting from any
          information posted on the Site by the User or/and anybody else on his
          behalf and account.
        </li>

        <li>
          The User is solely responsible for any data entered on the Site. The
          User who has entered the data is fully responsible for any wrong data
          entered and shall be liable for any and all action(s) taken by third
          party (whether civil/criminal). The Company however shall endeavour to
          use the best industry practice, of weeding out all wrong data/ false
          data entered by the User and undertakes to withdraw all such data
          within reasonable time after verifying the complaint.
        </li>

        <li>
          User of the website agrees to indemnify and keep bestpropertiesmohali Realty
          Services Limited indemnified from any wrong/false data entered into
          and hereby warrants and covenants that all data entered into the
          website is true and correct and belongs exclusively to him/it and not
          to any other third party.
        </li>
      </ol>
      <h5>
        <li>Prohibited Actions</li>
      </h5>
      <ol type="1">
        <li>
          User is restrained from allowing any person other than the authorized
          person(s) named in the application form to use the Service.
        </li>
        <li>
          The User undertakes not to resell or assign his/its rights or
          obligations under these Terms & Conditions. User also agrees not to
          make any unauthorized commercial use of the Service.
        </li>
        <li>
          The User shall use the Service only for the purpose for which it is
          subscribed.
        </li>
        <li>
          The User shall comply with all applicable laws (and shall not
          contravene any applicable law) of India relating to the Services,
          including any regulation made pursuant thereto.
        </li>
        <li>
          The User shall not print, download, duplicate or otherwise copy,
          delete, vary or amend or use any data or personal information posted
          by any User on Site except such data and information which is posted
          by the particular User himself/itself.
        </li>
        <li>
          The User shall not use the Service for any unlawful and fraudulent
          purpose.{" "}
        </li>
        <li>
          The Service shall not be used to send or receive any message, which is
          offensive on moral, religious, racial or political grounds or of
          abusive, indecent, obscene, defamatory or menacing nature.
        </li>
        <li>
          User is prohibited from selling its listing any further to any third
          or unrelated party. The services are meant for consumption by the User
          who has paid the amount of subscription.
        </li>
        <li>
          The User is prohibited from postings any information or content on
          Site, which directly or indirectly cause any threat or/and harassment
          or/and annoyance or/and anxiety or/and any other inconvenience of
          whatsoever nature.
        </li>
        <li>
          The User shall not infringe intellectual property rights of any
          person/party and/or retain information in any computer system or
          otherwise with an intention to do so.
        </li>
        <li>
          The User agrees not to make use of anyone else's information other
          than as necessary to complete any transactions in which User is
          involved.
        </li>
        <li>
          The User shall not violate, or attempt to violate the security of the
          Site and/or any web sites linked to bestpropertiesmohali.com or gain
          un-authorized access to any information regarded as private by other
          User(s) or persons, including but without limitation to accessing data
          and information not intended for them or logging onto a server or
          account which the User is not authorized to access, attempting to
          probe, scan or test the vulnerability of a system or network or
          attempting to breach security or authentication.
        </li>
        <li>
          The User shall be prohibited to introduce, post or transmit any
          information or software, which contains a virus, worm or other harmful
          component into the internet or Site network system.
        </li>
        <li>
          In the event that the User breaches any of the above mentioned
          covenants, the Company shall have the right to delete any material
          relating to the violations. The Company reserves the unilateral right
          to suspend or/and deactivate the User's access to the Site Service
          and/or any other related facility in case of violation of terms of
          use. In addition to the right to indemnity available to the Company,
          the Company shall have the right to recourse to any legal remedy
          against the User to recover the loss suffered by the Company and the
          harm caused to the reputation of the Company, due to such violation by
          the User.
        </li>
        <li>
          In the event that the User breaches terms of use of service, the
          Company reserves the unilateral right to suspend or/and deactivate the
          User's access to the Site Service and/or any other related facility.
          Further Company at its sole discretion, permanently or temporarily,
          blacklist a User. The unutilized amount paid towards the service being
          the agreed quantum of liquidated damages shall stand forfeited by the
          Company. In addition to the right to indemnity available to the
          Company, the Company shall have the right to recourse to any legal
          remedy against the User to recover the loss suffered by the Company
          and the harm caused to the reputation of the Company, due to violation
          by the User.
        </li>
        <li>
          The Users shall avail Services on bestpropertiesmohali.com or any other related
          site for lawful purposes alone. Transmission or/and distribution
          or/and storage of material or/and conduct in violation of any
          applicable local or/and state or/and Central or/and foreign law or/and
          regulation is prohibited. This includes without limitation any
          unauthorized use of material protected by patent or/and copyright
          or/and trademark or/and other intellectual property right, material
          that is obscene or/and defamatory or/and libelous or/and constitutes
          an illegal threat, or/and violates rights of privacy or publicity,
          or/and violates export control laws. The User may use the information
          on our site only to the extent necessary to facilitate the related
          transactions.
        </li>
        <li>
          Company strongly opposes spam, which floods the Internet with unwanted
          and unsolicited email and deteriorates the performance and
          availability of the bestpropertiesmohali.com site. All forms of spam, or any
          other activities that have the effect of facilitating spam, are
          strictly prohibited.
        </li>
        <li>
          Company also prohibits the use of another internet service to send or
          post spam to drive visitors to your site hosted on or through
          bestpropertiesmohali.com site, whether or not the messages were originated by
          you, under your direction, or by or under the direction of a related
          or unrelated third party.
        </li>
        <li>
          You shall not use any "deep-link", "page-scrape", "robot", "spider" or
          other automatic device, program, algorithm or methodology, or any
          similar or equivalent manual process, to access, acquire, copy or
          monitor any portion of the Site or any Content, or in any way
          reproduce or circumvent the navigational structure or presentation of
          the Site or any Content, to obtain or attempt to obtain any materials,
          documents or information through any means not otherwise made
          available through the Platform. We reserve Our right to bar any such
          activity.
        </li>
        <li>
          The User shall not attempt to gain unauthorized access to any portion
          or feature of the Platform, or any other systems or networks connected
          to the Platform or to any server, computer, network, or to any of the
          services offered on or through the Platform, by hacking, password
          "mining" or any other illegitimate means.
        </li>
        <li>
          You shall not engage in advertising to, or solicitation of, other
          Users of the Platform to buy or sell any services, including, but not
          limited to, services related to that being displayed on the Site or
          related to us.
        </li>
        <li>
          The Content posted does not necessarily reflect Company views. In no
          event shall Company assume or have any responsibility or liability for
          any Content posted or for any claims, damages or losses resulting from
          use of Content and/or appearance of Content on the Site. You hereby
          represent and warrant that You have all necessary rights in and to all
          Content which You provide and all information it contains and that
          such Content shall not infringe any proprietary or other rights of
          third parties or contain any libelous, tortious, or otherwise unlawful
          information.
        </li>
        <li>
          You agree that You shall not host, display, upload, modify, publish,
          transmit, update or share any information on the Site, that:
        </li>
      </ol>
      <ol type="a">
        <li>
          belongs to another person and to which the user does not have any
          right;
        </li>
        <li>
          is defamatory, obscene, pornographic, paedophilic, invasive of
          another's privacy, including bodily privacy, insulting or harassing on
          the basis of gender, libellous, racially or ethnically objectionable,
          relating or encouraging money laundering or gambling, or otherwise
          inconsistent with or contrary to the laws in force;
        </li>
        <li>is harmful to Child,</li>
        <li>
          infringes any patent, trademark, copyright or other proprietary
          rights;
        </li>
        <li>violates any law for the time being in force;</li>
        <li>
          deceives or misleads the addressee about the origin of the message or
          knowingly and intentionally communicates any information which is
          patently false or misleading in nature but may reasonably be perceived
          as a fact;
        </li>
        <li>impersonates another person;</li>
        <li>
          threatens the unity, integrity, defence, security or Sovereignty of
          India, friendly relations with foreign States, or public order, or
          causes incitement to the commission of any cognizable offence or
          prevents investigation of any offence or is insulting any foreign
          States;
        </li>
        <li>
          contains software virus or any other computer code, file or program
          designed to interrupt, destroy or limit the functionality of any
          computer resource;
        </li>
        <li>
          is patently false and untrue, and is written or published in any form,
          with the intent to mislead or harass a person, entity or agency for
          financial gain or to cause
        </li>
      </ol>
      <h5>
        <li>Use of Information/Data Supplied</li>
      </h5>
      <p>
        The User hereby agrees and irrevocably authorizes that the Company has
        the right to:
      </p>
      <ol type="1">
        <li>
          All copyright and/or know-how and/or any other related intellectual
          property rights to the Services of bestpropertiesmohali.com including listings,
          details of Users, and any information otherwise made available to User
          in the Service, shall become and remain the sole and exclusive
          property of the Company and the User shall have no claims to the same.
          In the event the User has contributed any content to bestpropertiesmohali.com
          in any manner whatsoever, all intellectual property rights to the same
          shall become the absolute property of the Company, including all
          intellectual property rights and the User shall have no right or claim
          over the same, in any manner whatsoever. In the event that the User
          during the term of his Agreement or any time thereafter, uses such
          intellectual property in any other website or related activity, the
          same shall be considered as an infringement of the intellectual
          property rights of the Company and the Company shall have the right to
          take recourse to whatever legal remedial action is required, in the
          given facts and circumstances, the costs and peril of which will lie
          at the end of the User.
        </li>

        <li>
          Use for the Company's own purpose, any data or/and information
          supplied by the User in connection with this Agreement, and/or pass on
          such information to any other associated companies or selected third
          parties.
        </li>

        <li>
          Retain all data or/and information supplied by the User while using
          the Service to remain at bestpropertiesmohali.com for the exclusive use of the
          Company in accordance with service agreement with the User,
          notwithstanding any termination of the Agreement or suspension of the
          Service to the User herein. Anything contrary to the above, unless
          specifically put down in writing, following the termination or
          suspension of the Service to the User, all such data and information
          shall remain in the Company's property, records and databases as the
          exclusive property of the Company, for all times to come.
        </li>

        <li>
          "By entering Mobile Number on bestpropertiesmohali Online Portal i.e.
          bestpropertiesmohali.com while registering for an account for receiving alerts,
          contacting a property, seller/buyer, user gives expressly consent to
          the Company and its Partners/ Vendors and sub-partner, sub vendor to
          send alerts, contact details promotional SMS and Promotional Calls to
          the mobile number entered by the user, irrespective of whether the
          same is on the DNC list or any other such data base. The user further
          agrees that, in order to satisfy and fulfil the user's property
          requirement, the Company may share the recording of the aforementioned
          promotional call between the user and a company representative with
          the concerned agents, partners, vendors, and sub-partners. In case
          user does not want to receive these messages on his/her mobile number,
          and not agreed any of the terms stipulated herein, he/she shall not
          submit his/her mobile number with bestpropertiesmohali.com."
        </li>
      </ol>
      <h5>
        <li>Intellectual Property Rights</li>
      </h5>
      <p>
        All logos, brands, trade marks and Service marks ("Marks") appearing in
        bestpropertiesmohali are the properties either owned or used under license by
        bestpropertiesmohali or its associates. All rights accruing from the same,
        statutory or otherwise, shall wholly vest with bestpropertiesmohali and its
        associates. The access to bestpropertiesmohali does not confer upon the User any
        license or right to use in respect of these Marks and therefore the use
        of these Marks in any form or manner, whatsoever is strictly prohibited.
        Any violation of the above would constitute an offence under the
        prevailing laws of India.<br></br>
        bestpropertiesmohali Realty Services respects the Intellectual Property Rights of
        all, it has and will continue to adhere to all the laws applicable in
        India in this respect. bestpropertiesmohali shall protect and respect the
        Intellectual Property Rights of the users as well as third parties to
        the best of its ability. In a case where a User(s) are found to be using
        bestpropertiesmohali as a platform to infringe the Intellectual Property Rights
        of others, bestpropertiesmohali will be free to terminate this Agreement
        forthwith without any notice to the user.<br></br>
        By allowing Users to access bestpropertiesmohali, grants the Users a limited,
        non-exclusive, non-assignable, revocable license (the "License") to
        access and use the Services, provided that the User is in compliance
        with the terms and conditions of the Agreement.<br></br>
        Unless expressly agreed to in writing, nothing contained herein shall
        give the User a right to use any of the Site's trade names, trademarks,
        service marks, logos, domain names, information, questions, answers,
        solutions, reports and other distinctive brand features, save according
        to the provisions of these Terms. All logos, trademarks, brand names,
        service marks, domain names, including material, designs, and graphics
        created by and developed by the Site and other distinctive brand
        features of the Site are the property of the Company. Furthermore, with
        respect to the Site created by the Company, the Company shall be the
        exclusive owner of all the designs, graphics and the like, related to
        the Site.
      </p>
      <h5>
        <li>Confidentiality</li>
      </h5>
      <ol type="1">
        <li>
          For the purpose of this Agreement and attachments thereto and all
          renewals, "Confidential Information" means all financial, commercial,
          technical, operational, staff, management and other information, data
          and know-how relating to the Project/property or to a party (the
          "Disclosing Party" herein the Company ) or any other members of the
          Disclosing Party's group of companies ( including, without limitation,
          as to products and services, assets, customers, date and database,
          suppliers or employees), which may be supplied to or may otherwise
          come into the possession of the other (the " Receiving Party ", herein
          the User ), whether orally or in writing or in any other form, and
          which is confidential or proprietary in nature or otherwise expressed
          by the Disclosing Party or by any of its Associates to be
          confidential, and not generally available to the public.
        </li>
        <li>
          The Receiving Party shall keep confidential and secret and not
          disclose to any third party the Confidential Information nor any part
          of it, except to any of the Receiving Party's Associates, if required
          and upon prior permission in writing from the Disclosing Party. The
          Receiving Party agrees to take all possible precautions with regard to
          protecting confidential information from any third party and shall
          ensure that all its Associates to whom such disclosure e is made will
          act in accordance with the terms of this Agreement as if each of them
          were a party to this Agreement, and if required obtain a written
          statement from each of its employees/associates having access to such
          Proprietary Information undertaking to abide by the confidentiality
          conditions. All Proprietary Information shall be kept separate and
          exclusive and at the usual place of business (or residence as the case
          may be) of the Receiving Party.
        </li>
        <li>
          Further no use, reproduction, transformation or storage of the
          Proprietary Information shall be made by the User without the prior
          written permission of the Company, except where required to be
          disclosed pursuant to any applicable law or legal process issued by
          any court or the rules of any competent regulatory body.
        </li>
        <li>
          On request of the Disclosing Party made at any time, including at the
          time of termination of this Agreement, the Receiving Party shall
          deliver back to the Disclosing Party all original documents, records,
          data and other material in the possession, custody or control of the
          Receiving Party that bear or incorporate any part of the Proprietary
          Information. The obligations of confidentiality set out in this
          Agreement shall continue to be in force notwithstanding termination of
          this Agreement for any reason whatsoever.
        </li>
        <li>
          All information and data submitted by the User shall become the
          property of the Company. However all such information shall be kept
          strictly confidential and the Company shall not, subject to "Violation
          of Terms & Conditions" clause, release any such data and information
          to any third party without the prior consent of the User.
        </li>
        <li>
          The User has access to only his/its own data and information stored in
          the database of bestpropertiesmohali.com (subject to prior confirmation of
          identity) and nothing more. The User may edit or amend such data and
          information from time to time.
        </li>

        <li>
          All confidential information (including name, e-mail address etc.)
          voluntarily revealed by the User in chat and bulletin board areas, is
          done at the sole discretion and risk of the User. If such information,
          collected by a third party is misused or results in unsolicited
          messages from such third parties, then such actions are beyond the use
          beyond the control and liability of Company and the company accepts no
          responsibility or liability whatsoever for such actions.
        </li>
      </ol>
      <h5>
        <li>Variation</li>
      </h5>
      <ol type="1">
        <li>
          The right to amend vary or change the terms and conditions contained
          herein and in the Services guide at any time exclusively rests with
          the Company.
        </li>
        <li>
          This Agreement will be periodically updated and the Terms of Use will
          be changed from time to time and the changed or updated Agreement will
          be posted at bestpropertiesmohali.com. The User should visit the site
          periodically to review the latest Terms of Use. For avoidance of any
          doubt, the User's continued use of the Service constitutes an
          affirmation and acknowledgment of the amended terms and conditions.
        </li>
      </ol>
      <h5>
        <li>Discontinuation or Modification to Services</li>
      </h5>
      <ol type="1">
        <li>
          The Company reserves the unilateral right to add to /and/or change
          and/or delete and/or end the Service at any time with or without
          notice to the User. Except for paid service, a pro-rated refund shall
          be effected for the remaining unused period.
        </li>
        <li>
          There shall be no liability on behalf of the Company to the User or
          any third party in case the Company exercises its unilateral right to
          modify or discontinue the Service.
        </li>
      </ol>
      <h5>
        <li>Maintenance</li>
      </h5>
      <p>
        The Company may at its sole discretion and without assigning any reason
        whatsoever at any time deactivate or/and suspend the Services (as the
        case may be) without notice to carry out system maintenance or/and
        upgrading or/and testing or/and repairs or/and other related work and/or
        the User's access to bestpropertiesmohali.com. Without prejudice to any other
        provisions of this Agreement, the Company shall not be liable for any
        loss or/and damage or/and costs or/and expense that the User may suffer
        or incur, and no fees or/and charges payable by the User to the Company
        shall be deducted or refunded or rebated, as a result of such
        deactivation or/and suspension.
      </p>
      <h5>
        <li>Third party links and resources in Our Website</li>
      </h5>
      <p>
        The content of other websites, services, goods or advertisements that
        may be linked to Our Site is not maintained or controlled by Us. We
        therefore are not responsible for the availability, content or accuracy
        or privacy practices of other websites, services or goods that may be
        linked to, or advertised on, Our website.
        <br></br>
        Where this Site contains hyperlinks to websites operated by third
        parties these linked websites are not under the control of the Company
        and the Company is not responsible for the contents of any linked
        website. The Company provides these hyperlinks to you for convenience
        only and the inclusion of any link does not imply any endorsement of the
        linked website by bestpropertiesmohali Realty Services Limited. Your link or use
        of any such website entirely at your own risk.
      </p>
      <ul>
        <h5>The Company does not:</h5>
      </ul>
      <ol type="a">
        <li>
          Make any warranty, express or implied, with respect to the use of the
          links provided on, or to, Our Site;
        </li>
        <li>
          Guarantee the accuracy, completeness, usefulness or adequacy of any
          other website, services, goods or advertisements that may be linked to
          Our Site; or
        </li>
        <li>
          Make any endorsement, express or implied, of any other websites,
          services, goods or advertisements that may be linked to Our Site.
        </li>
      </ol>
      <p>
        The Company accepts no liability for any damage or loss, however caused,
        in connection with the use of or reliance on any information, material,
        products or services contained on or accessed through any linked
        website.<br></br>
        Our Site may also contain third party advertisements which contain
        embedded hyperlinks to websites operated by third parties. All third
        party advertising is paid for by the relevant third party advertiser,
        which you can accept by linking to the third party advertisers and are
        not recommendations or endorsements by bestpropertiesmohali Realty Services
        Limited. The third party advertiser is solely responsible for any
        representations or offers made by it and for the delivery of goods or
        services you agree to purchase from the third party website.
      </p>
      <ol type="1">
        <li>
          Company may from time to time recommend, provide you with access to,
          or enable third party software, applications ("Apps"), products,
          services or website links (collectively, "Third Party Services") for
          your consideration or use, including via the Company App Store. Your
          purchase, access or use of any such Third Party Services is solely
          between you and the applicable third party services provider ("Third
          Party Provider").
        </li>

        <li>
          Company does not have any control or does not determine or advise or
          in any way involve itself in the offering or acceptance of such
          commercial/contractual terms between the users and Third Party
          Providers.
        </li>

        <li>
          Company neither make any representation or warranty as to specifics
          (such as quality, value, saleability, etc.) of the products or
          services proposed to be sold or offered to be sold or purchased on the
          Platform. The availability of Third Party Services on Company's
          websites or the integration or enabling of such Third Party Services
          with the Company services does not constitute or imply an endorsement,
          authorization, sponsorship, or affiliation by or with Company.
        </li>

        <li>
          Company is neither responsible nor accepts liability for any errors or
          omissions or non-performance or breach of any contract entered into
          between users and Third Party Providers on the Platform. Company
          cannot and does not guarantee that the concerned Users and/or Third
          Party Providers will perform any transaction concluded on the
          Platform. Company is not responsible for unsatisfactory or
          non-performance of services or damages or delays as a result of
          products/services.
        </li>

        <li>
          Company is operating an online advertisement platform and assumes the
          role of an intermediary, and does not at any point of time during any
          transaction between user and Third Party Providers on the Platform
          come into or take possession of any of the products or services
          offered by Third Party Providers. At no time shall Company hold any
          right, title or interest over the products nor shall Company have any
          obligations or liabilities in respect of such contract entered into
          between User and Third Party Providers.
        </li>
        <li>
          Guarantee the accuracy, completeness, usefulness or adequacy of any
          other website, services, goods or advertisements that may be linked to
          Our Site; or
        </li>

        <li>
          In case of complaints from the user pertaining to quality or any other
          such issues, Company is not obligated to intervene in any dispute
          arising between you and a Third Party Provider.
        </li>

        <li>
          Company is not responsible or liable to anyone for discontinuation or
          suspension of access to, or disablement of any Third Party Service.
        </li>

        <li>
          If you install or enable a Third Party Service for use, you grant us
          permission to allow the applicable Third Party Provider to access your
          data and other materials and to take any other actions as required for
          interoperation of the Third Party Service with our Services, and any
          exchange of data or other materials or other interaction between you
          and the Third Party Provider is solely between you and such Third
          Party Provider. Company is not responsible for any disclosure,
          modification or deletion of your data or other materials, or for any
          corresponding losses or damages you may suffer, as a result of access
          by a Third Party Service or a Third Party Provider to your data or
          other materials.
        </li>

        <li>
          Under no circumstances shall Company be liable for any direct,
          indirect, incidental, special, consequential, punitive, extraordinary,
          exemplary or other damages whatsoever that result from any Third Party
          Services or your contractual relationship with any Third Party
          Provider. These limitations shall apply even if Company has been
          advised of the possibility of such damages. The foregoing limitations
          shall apply to the fullest extent permitted by applicable law.
        </li>

        <li>
          You agree to indemnify and hold us and (as applicable) our parent,
          subsidiaries, affiliates, Company partners, officers, directors,
          agents, employees, and suppliers harmless from any claim or demand,
          including reasonable attorneys' fees, arising out of your use of a
          Third Party Service or your relationship with a Third Party Provider.
        </li>

        <b>
          <li>
            REFUNDS: We do not offer any refunds against goods purchased from
            the Platform for Third Party Services.
          </li>
        </b>
      </ol>
      <h5>
        <li>The Company does not:</li>
      </h5>
      <ol type="1">
        <li>
          Either party to this agreement may terminate the Agreement by giving
          prior notice of 30 days in writing.
        </li>
        <li>
          It shall be on the discretion of the Company that the period of notice
          of 30 days may be waived or a shorter period of notice may be accepted
          in writing from the User.
        </li>
        <li>
          However, the Company irrespective of above mentioned clauses may
          terminate this Agreement with immediate effect, without prior notice
          to the User and without assigning any reason/s whatsoever (a) if in
          the opinion of the Company, the User has breached any of the terms and
          conditions of this agreement or/and, (b) if, in the opinion of the
          Company or/and any regulatory authority, it is not in the public
          interest to continue providing the Service to the User for any reason
          or/and, (c) if the User is declared a bankrupt or/and the User enters
          into any compromise or arrangement with its creditors and further
          without any prejudice to any/all other rights.
        </li>
      </ol>
      <h5>
        <li>Liabilities upon Termination</li>
      </h5>
      <ol type="1">
        <li>
          If the Agreement is terminated pursuant to provisions set out in
          Clause XIX or XX above, without prejudice to any other remedies
          available to the Company, the User shall be liable for Subscription
          Fees payable until the Date of Termination.
        </li>
        <li>
          The amounts due and payable to the Company by the User upon
          termination shall be payable within 30 days of the relevant Date of
          Termination.
        </li>
      </ol>
      <h5>
        <li>Suspension of Service</li>
      </h5>
      <ol type="1">
        <li>
          If any monies payable by the User to the Company are not paid on the
          due date, the Company may without prejudice to any other rights or
          remedies that may be available to it suspend the Service provided to
          the User.
        </li>
        <li>
          When the Services, subscribed for, is suspended, it shall be deemed to
          be terminated. The date shall be such as stipulated by the Company and
          the User shall be liable for all the charges and fees incurred up to
          the date.
        </li>
        <li>
          Upon subsequent payment by the User of such monies as demanded by the
          Company, the Company may at its discretion and subject to such terms
          as it deems proper, reconnect the Service.
        </li>
      </ol>
      <h5>
        <li>Money back Policy</li>
      </h5>
      <ol type="1">
        <li>
          In the event a User is unsatisfied with the responses received against
          a Listing posted using the Money back Package provided by the Company,
          money back policy can be initiated by a User using the Feedback link
          on bestpropertiesmohali.com or by writing to moneyback@bestpropertiesmohali.com or by
          calling the customer care number.
        </li>
        <li>
          For claiming any money back, there should be at least one property
          posted using the package.
        </li>
        <li>
          User shall be first offered an extension of duration for the Listing
          that was posted using Moneyback Package for Free, to evaluate the
          responses again.
        </li>

        <li>
          If the user is still not satisfied with the responses offered after
          the duration is extended, Moneyback shall be initiated by the Company.
        </li>

        <li>
          Moneyback can be initiated within the lifetime of the listing (i.e. 60
          days from date of posting).
        </li>

        <li>
          Moneyback can only be initiated against the same Order ID & Customer
          Name as mentioned during Order Booking.
        </li>

        <li>
          Order Booking should be in the Client's name as the refund will be
          processed in the same name..
        </li>

        <li>
          For orders that were paid through online payment modes, the refunds
          will be processed after deduction of charges incurred by the Payment
          Gateway.
        </li>

        <li>
          Refunds will be processed within 30 business days from receipt &
          acceptance of all relevant documents.
        </li>
      </ol>
      <h5>
        <li>Money back Policy</li>
      </h5>
      <p>
        In the event of violation of Terms, the Company in its sole discretion
        may pursue any of its legal remedies, including but not limited to the
        immediate deletion of any offending material from its site, or/and
        cancellation of your account, and/or the exclusion of any person(s) who
        may have violated any Terms & Conditions hereto and seeking any
        injunctive relief from any court of competent jurisdiction. Company can
        also pursue violators with claims that they have violated various
        criminal and/or civil law provisions as applicable under the relevant
        Acts/Rules, etc. of the land. bestpropertiesmohali.com will cooperate with any
        investigation by any Central or/and State or/and local body or/and any
        court or/and tribunal having the competence to carry out the same. Such
        cooperation may be without notice to the User. If Company believes in
        its sole discretion that any advertisement or/and services may create
        liability for Company, Company may take any actions that it believes to
        be prudent or necessary to minimize or/and eliminate its potential
        liability, including but not limited to, the release of User
        information. In sum, Company reserves the right to refuse service to
        anyone at any time, or/and to remove any listings or/and any
        advertisements for any reason, and without notice.
      </p>
      <h5>
        <li>Personal Information</li>
      </h5>
      <p>
        Your submission of personal information through this Site is governed by
        our Privacy Policy.
      </p>
      <h5>
        <li>ERRORS, INACCURACIES AND OMISSIONS</li>
      </h5>
      <ol type="1">
        <li>
          Occasionally there may be information on the site or in the Service
          that contains typographical errors, inaccuracies or omissions that may
          relate to product descriptions, pricing, promotions, offers, and
          availability. We reserve the right to correct any errors, inaccuracies
          or omissions, and to change or update information or cancel orders if
          any information in the Service or Site, is inaccurate at any time
          without prior notice (including after you have submitted your order).
        </li>
        <li>
          For claiming any money back, there should be at least one property
          posted using the package.
        </li>
        <li>
          We however undertake no obligation to update, amend or clarify
          information in the Service or Site, including without limitation,
          pricing information, except as required by law. No specified update or
          refresh date applied in the Service or on Site, should be taken to
          indicate that all information in the Service or on Site has been
          modified or updated.
        </li>
      </ol>
      <h5>
        <li>Disclaimer</li>
      </h5>
      <ol type="1">
        <li>
          The User hereby agrees that use of the Service is at the User's sole
          risk. The Service is provided on an "as is" or/and on an "as
          available" basis. Company expressly disclaims all warranties of any
          kind, whether express or implied, including, but not limited to the
          implied warranties of merchantability, fitness for a particular
          purpose and non-infringement.
        </li>
        <li>
          The Company makes no warranty that the Services shall meet User's
          requirements, that the Services shall be uninterrupted or/and timely
          or/and secure or/and error free.
        </li>
        <li>
          This Site is vulnerable to data corruption, interception, tampering,
          viruses as well as delivery errors and we do not accept liability for
          any consequence that may arise therefrom. We may need to make the Site
          unavailable with or without notice to carry out maintenance or upgrade
          work. We accept no liability for any interruption or loss of Service.
          Further the Company does not warrant that any of the websites linked
          to bestpropertiesmohali.com be free of any operational hazards or error nor
          that it will be free of any virus or/and worm or/and any other harmful
          components.
        </li>
        <li>
          There are no express representations and warranties, whether express
          or implied, made by the Company regarding the accuracy, reliability
          and/or quality of any information transmitted and/or obtained through
          the use of the Services of bestpropertiesmohali.com. The User understands and
          agrees that any information or/and material and/or goods or/and
          services obtained through this Site is done at User's own discretion
          and risk and that User shall be solely responsible for any damage/s
          or/and cost or/and any other consequence/s resulting from any
          transaction. No advice or or/any information, whether oral or/and
          written, obtained by the User from bestpropertiesmohali.com or/and through
          or/and from the Service shall create any warranty not expressly stated
          herein. In case the there is any loss of information, caused due to
          any reason, whether as a result of any disruption of service,
          suspension and/or termination of the Service, the Company shall not be
          liable in any way for the same. Cancellations and alterations shall be
          effected only on the receipt of application regarding the same in
          writing.
        </li>
        <li>
          bestpropertiesmohali is only an intermediary as defined under sub-clause (w) of
          Section 2 of the Information Technology Act, 2000
        </li>
        <li>
          The content of bestpropertiesmohali is provided "as is" and on an "as
          available" basis, without warranties or representations of any kind,
          either express or implied.
        </li>
        <li>
          Views expressed by the Users are their own, bestpropertiesmohali does not
          endorse the same and shall not be responsible for them. No claim as to
          the accuracy and correctness of the information on the site is made
          although every attempt is made to ensure that the content is not
          misleading/ offensive/ inappropriate. In case any inaccuracy is or
          otherwise improper content is sighted on the website, please report it
          to report abuse. It is solely the User’s responsibility to evaluate
          the accuracy, completeness and usefulness of all opinions, advice,
          Services, real estate and other related information listed on the
          website. bestpropertiesmohali does not warrant that the access to website will
          be uninterrupted or error-free or that defects in website will be
          corrected.
        </li>
        <li>
          bestpropertiesmohali offers no guarantee no warrantees that there would be
          satisfactory response or any response at all, once the listing/banner
          is put on display.{" "}
        </li>
        <li>
          Users are strongly advised to independently verify the authenticity of
          any Pre-Launch offers received by them. bestpropertiesmohali does not endorse
          investment in any projects which have not received official sanction
          and have not been launched by the Builder/Promoter, users dealing in
          such projects shall be doing so entirely at their risk and
          responsibility.
        </li>
        <li>
          No information contained herein shall constitute an invitation or an
          offer to invest in bestpropertiesmohali or any of its Affiliates. Further,
          nothing contained in bestpropertiesmohali should be construed as a
          recommendation to use any product, process, equipment or formulation,
          in conflict with any patent, or otherwise and bestpropertiesmohali makes no
          representation or warranty, express or implied that, the use thereof
          will not infringe any patent, or otherwise.
        </li>
        <li>
          The Company shall not be liable for any disclosure of information
          concerning the User's account and/or particulars nor for any error
          and/or omissions and/or inaccuracy with regard to information so
          disclosed. In addition the Company shall further not be liable for any
          loss or damages sustained by reason of such disclosure, whether
          intentionally or inadvertently. All information are accepted in good
          faith and Company accept no responsibility whatsoever regarding the
          bonafide of the User, nor can any interviews be granted or/and
          correspondence entered into regarding any advertisement published.
        </li>
        <li>
          Once a listing or banner is displayed on Our Site, We do not guarantee
          or make warranties that there would be satisfactory response or any
          response at all.
        </li>
        <li>
          The Company is not liable or responsible for the quality or any
          misrepresentation or any liability or issue arising out of the
          Services availed by the User from third party service providers on or
          beyond the platform of bestpropertiesmohali.com. For any third party product or
          service which customer/user will buy through bestpropertiesmohali.com, the
          Customers/Users of Services shall take full responsibility to deal
          with third parties respective vendors at their own risk, cost and
          liability.
        </li>
        <li>
          Property descriptions and other information provided on Our Site are
          intended for information and marketing purposes and, whilst displayed
          in good faith, we will not in any circumstances accept responsibility
          for their accuracy. It is the responsibility of prospective
          buyers/tenants to satisfy themselves as to the accuracy of any
          property descriptions displayed and the responsibility of
          agents/sellers/brokers to ensure the accuracy and integrity of
          property descriptions provided on Our Site and in any property
          particulars.
        </li>
        <li>
          Any value estimates provided on Our Site are intended for general
          interest and information purposes only and should not be relied upon
          for any commercial transaction or similar use. These estimates are
          based on publicly available information which may be inaccurate or
          incomplete, and typical factors in certain locations. They will not
          take account of any factors which are unknown to us and should only be
          used as a general estimate. None of the information available on Our
          Site is intended to be a substitute for independent professional
          advice and users are recommended to seek advice from suitably
          qualified professionals such as surveyors and solicitors if relevant
          to their particular circumstances. We shall not be liable for any
          losses suffered as a result of relying on our value estimates.
        </li>
        <li>
          Company will not be liable for any time difference arising between a
          message released through a gateway/ server (provided to
          bestpropertiesmohali.com) and such message finally reaching the User from the
          concerned service provider.
        </li>
        <li>
          The User shall ensure that while using the Service, all prevailing and
          applicable laws, rules and regulations, directly or indirectly for the
          use of systems, service or equipment shall at all times, be strictly
          complied with by the User and the Company shall not be liable in any
          manner whatsoever for default of any nature regarding the same, by the
          User.
        </li>
        <li>
          In case a visitor to the portal is desirous of conducting a project
          site visit of the property he/it is interested in, it shall be the
          sole liability and duty of the Advertiser to arrange for such project
          site visits. The Company shall not be liable in any manner whatsoever,
          towards any liability and/or costs, arising out of such site visits
          including but not limited to non-fulfilment by the Advertiser in this
          regard.
        </li>
        <li>
          The Platinum Listing is listed for a period of two weeks only from the
          day the Order is activated. Company shall not be liable for any delay
          arising from the User's end in conversion of their property listing to
          Platinum Listing, resulting in the Platinum Ad listing not being
          showcased for the complete period of two weeks. This package cannot be
          transferred, adjusted or reimbursed.
        </li>
        <li>
          Company has no intention of violating any intellectual property or
          ancillary rights. If there is any violation, we request that the same
          be promptly brought to our attention.
        </li>
        <li>
          It is not the Company's policy to exercise any kind of supervisory or
          editorial control over and/or edit and/or amend any data and/or
          contents of any e-mails and/or posting of any information that may be
          inserted or/and made available or transmitted to a third party in or
          through bestpropertiesmohali.com and the User acknowledges the same. The User
          acknowledges and agrees that the Company has absolute discretion to
          refuse and/or suspend or/and terminate and/or delete and/or amend any
          artwork, materials and/or information and/or content of any data
          and/or information and/or posting so as, in the sole opinion of the
          Company, to comply with the prevailing legal framework and/or moral
          obligations as placed on the Company and in order to avoid infringing
          any third party's rights and/or any other rules and/or standards
          and/or codes of practices that may be applicable to the posting or
          bestpropertiesmohali.com and/or the internet.
        </li>
        <li>
          The Company is not involved in any transaction between any parties who
          are using Our Site. There are risks, which the User assumes when
          dealing with people who might be acting under false pretences and the
          same shall be borne by the User. The Site is a venue only and do not
          screen or/and censor or/and otherwise control the listings offered to
          other Users, nor does Company screen or/and censor or/and otherwise
          control the Users of its service. Company cannot and does not control
          the behaviour of the participants on this site. We cannot control
          whether or not Users of bestpropertiesmohali.com will complete the transactions
          they describe on Our Site. It is extremely important that the User
          takes care throughout his/its dealings with third party(s) and/or
          Users on this Site. Company does not accept or/and assume
          responsibility for the content or/and context of the User comment.
        </li>
        <li>
          The company's total liability under this agreement (whether in
          contract, tort (including negligence) or otherwise shall be limited to
          the total amount of fees or/and charges paid by the user to the
          company's site or the period immediately preceding two (2) months
          prior to the incident giving rise to the relevant claim.
        </li>
        <li>
          Company shall not be responsible for the advice, views and suggestions
          provided by the expert. The information contained herein should be
          used for reference only. Before relying on any such advice, please do
          an independent assessment at your end considering your specific
          requirements and the parameters you have in mind. bestpropertiesmohali.com will
          not be responsible for any claims arising out of the use of any
          information displayed herein.
        </li>
        <li>
          bestpropertiesmohali Realty Services Limited is only an intermediary offering
          its platform to advertise properties of Seller for a
          Customer/Buyer/User coming on its Website and is not and cannot be a
          party to or privy to or control in any manner any transactions between
          the Seller and the Customer/Buyer/User. All the offers and discounts
          on this Website have been extended by various Builder(s)/Developer(s)
          who have advertised their products. bestpropertiesmohali is only communicating
          the offers and not selling or rendering any of those products or
          services. Company is not responsible for any non-performance or breach
          of any contract entered into between Customer/Buyer/User and Sellers.
          Company cannot and does not guarantee that the concerned
          Customer/Buyer/User and/or Sellers will perform any transaction. At no
          time shall Company hold any right, title or interest over the
          products/services nor shall Company have any obligations or
          liabilities in respect of such contract entered into between
          Customer/Buyer/User and Sellers. It neither warrants nor is it making
          any representations with respect to offer(s) made on the site.
          bestpropertiesmohali Realty Services Limited shall neither be responsible nor
          liable to mediate or resolve any disputes or disagreements between the
          Customer/Buyer/User and the Seller and both Seller and
          Customer/Buyer/User shall settle all such disputes without involving
          bestpropertiesmohali Realty Services Limited in any manner.
        </li>
        <li>
          If there is any dispute between the Users/participants on this Site,
          it is agreed upon by the Users/participants that Company has no
          obligation whatsoever to be involved in any such dispute/s. In the
          event that the User has a dispute with one or more User/s or/and
          participant/s, the User hereby undertakes not to make any claims
          or/and demands or/and damages (actual or/and consequential) of every
          kind or/and nature or/and known or/and unknown or/and suspected or/and
          unsuspected or/and disclosed or/and undisclosed, arising out of or/and
          in any way related to such disputes and/or our service against the
          company or/and their officers or/and employees or/and agents or/and
          successors.
        </li>
        <li>
          In no case shall the Company, Our directors, officers, employees,
          affiliates, agents, contractors, interns, suppliers, service providers
          or licensors be liable for any injury, loss, claim, or any direct,
          indirect, incidental, punitive, special, or consequential damages of
          any kind, including, without limitation lost profits, lost revenue,
          lost savings, loss of data, replacement costs, or any similar damages,
          whether based in contract, tort (including negligence), strict
          liability or otherwise, arising from Your use of any of the Service or
          any products, or for any other claim related in any way to Your use of
          the Service, including, but not limited to, any errors or omissions in
          any content, or any loss or damage of any kind incurred as a result of
          the use of the Service or any content (or product) posted,
          transmitted, or otherwise made available via the Service, even if
          advised of their possibility.
        </li>
        <li>
          bestpropertiesmohali has endeavoured to ascertain the requirement of RERA
          registration. The advertiser is hereby obligated to provide RERA
          registration number for promoting a RERA registered project in terms
          of Real Estate (Regulation & Development) Act, 2016. bestpropertiesmohali is a
          platform for advertisement and does not vouch for the project or the
          details provided in the advertisement.
        </li>
        <li>
          You acknowledge that third party services are available on the
          platform. We may have formed partnerships or alliances with some of
          these third parties from time to time in order to facilitate the
          provision of certain services to you. However, you acknowledge and
          agree that at no time are we making any representation or warranty
          regarding any third party's services nor will we be liable to you or
          any third party for any consequences or claims arising from or in
          connection with such third party including, and not limited to, any
          liability or responsibility for, death, injury or impairment
          experienced by you or any third party. You hereby disclaim and waive
          any rights and claims you may have against us with respect to third
          party's / merchants services.
        </li>
        <li>
          We are not responsible for third party services so use them at your
          own risk. If you use third party services on the company platform, you
          permit us to send your data to those services. If you use third party
          services you agree that we do not provide a warranty, so get advice
          beforehand.
        </li>

        <li>
          bestpropertiesmohali does not endorse any of the opportunities that appear on
          this website nor make any recommendations regarding same to any
          Investor. Prospective investors are not to construe anything on the
          website as investment, business, legal or tax advice and the content
          contained herein does not constitute an offer by bestpropertiesmohali to sell,
          solicit or make an offer to buy an investment interest. Any
          information made available from our website or links to websites, does
          not represent a solicitation of an offer to buy or sell any property.
          It also does not constitute an offer to provide investment advice,
          service or assistance on particular investment or transaction. Direct
          and indirect purchase of real property involves significant risk and
          investments may lose value and are not insured by any Governmental
          Agency nor are they guaranteed by bestpropertiesmohali. It is the
          responsibility of the recipient to verify the integrity and
          authenticity of the information made available before making any
          investment.
        </li>
      </ol>
      <h5>
        <li>Limitation of Liability</li>
      </h5>
      <ol type="1">
        <li>
          User agrees that neither Company nor its group companies, directors,
          officers or employee shall be liable for any direct or/and indirect
          or/and incidental or/and special or/and consequential or/and exemplary
          damages, resulting from the use or/and the inability to use the
          service or/and for cost of procurement of substitute goods or/and
          services or resulting from any goods or/and data or/and information
          or/and services purchased or/and obtained or/and messages received
          or/and transactions entered into through or/and from the service
          or/and resulting from unauthorized access to or/and alteration of
          user's transmissions or/and data or/and arising from any other matter
          relating to the service, including but not limited to, damages for
          loss of profits or/and use or/and data or other intangible, even if
          Company has been advised of the possibility of such damages. User
          further agrees that Company shall not be liable for any damages
          arising from interruption, suspension or termination of service,
          including but not limited to direct and/or indirect and/or incidental
          or/and special consequential or/and exemplary damages, whether such
          interruption or/and suspension or/and termination was justified or
          not, negligent or intentional, inadvertent or advertent. User agrees
          that Company shall not be responsible or liable to user, or anyone,
          for the statements or conduct of any third party of the service. In
          sum, in no event shall Company's total liability to the User for all
          damages or/and losses or/and causes of action exceed the amount paid
          by the User to Company, if any, that is related to the cause of
          action.
        </li>
        <li>
          The Company takes no responsibility/liability whatsoever for shortage
          or non-fulfilment of the service/s on Company or any other related
          site due to or arising out of technical failure or/and malfunctioning
          or/and otherwise and the User hereby undertakes that in such
          situation, the User shall not claim any right/damages/ relief, etc.
          against the Company for "Deficiency of service" under The Consumer
          Protection Act or any other Act/Rules, etc.
        </li>
        <li>
          Company shall not be liable for any and all costs, charges, expenses,
          etc. incurred in relation to the downloading fees by third party,
          airtime, ISP connection costs, etc., of which are to be borne by the
          user personally.
        </li>
        <li>
          Company shall not be responsible for any malfunctioning,
          non-functioning of the mobile phone or any other application, and or
          any damage, loss, either direct or indirect arising out of the use of
          the bestpropertiesmohali mobile application on your mobile phone.
        </li>
      </ol>
      <h5>
        <li>Indemnity</li>
      </h5>
      <p>
        User hereby agrees to indemnify, hold harmless and to settle any third
        party lawsuit or proceedings brought against the Company or any of its
        Directors/ employee/ KMP with regard to any claim arising from the
        advertisement/ wrongful posting of property/ unauthorised posting of
        property or the fact that the User Content, Site and/or User features
        infringe or tend to infringe any copyright, trade secret or trademark of
        such third party or the content of such post or advertisement is in any
        manner inconsistent with or in breach of any existing Agreement or law
        as applicable in India The User further unilaterally agrees to
        indemnify, reimburse and hold harmless, without objection, the Company,
        its officers, directors, employees and agents from and against any
        claims, actions and/or demands and/or liabilities and/or losses and/or
        damages whatsoever arising from or resulting from their use of
        bestpropertiesmohali whether directly or indirectly.
      </p>
      <h5>
        <li>Notice</li>
      </h5>
      <ol type="1">
        <li>
          All notices (a) if sent to the User to the address identified on the
          Application Form and (b) if sent to the Company to such address as
          provided in writing for such notice purposes; provided, however, that
          all invoices and payments shall be sent to the attention of The
          Company's Finance department, all legal notices shall be sent to the
          attention of the Company's legal department, and all other
          correspondence shall be sent to the attention of the account manager
          specified by the Company. Notice shall be deemed given upon receipt
          when delivered personally and upon receiving acknowledgment / written
          verification of receipt from overnight courier.
        </li>
        <li>
          Grievance Redressal Mechanism: Any complaints, abuse or concerns with
          regards to content and or comment or breach of these terms shall be
          immediately informed to the designated Grievance Officer as mentioned
          below via in writing or through email to grievance@bestpropertiesmohali.com or
          Mr. Sandeep Dhatwalia ("Grievance Officer"). Mr. Sandeep Dhatwalia
          (Grievance Officer)
        </li>
      </ol>
      <p >www.bestpropertiesmohali.com</p>
      <p>
        bestpropertiesmohali Realty Services Limited<br></br>
        Times Centre<br></br>
        FC-6, Film City<br></br>
        Sector 16-A, Noida - 201301, India<br></br>
        Email:<a > grievance@bestpropertiesmohali.com</a>
      </p>
      <h5>
        <li>Non-exclusive Remedy</li>
      </h5>
      <p>
        Termination or expiration of this Agreement, in part or in whole, shall
        not limit either party from pursuing other remedies available to it, nor
        shall either party be relieved of its obligation to pay all fees that
        are due and owing under this Agreement through the effective date of
        termination. Neither party shall be liable to the other for any damages
        resulting solely from termination as permitted herein.
      </p>
      <h5>
        <li>Waiver</li>
      </h5>
      <p>
        The failure of Company to exercise or enforce any right or provision of
        these Terms & Conditions shall not constitute a waiver of such right or
        provision. If any provision of these Terms & Conditions is found by a
        court of competent jurisdiction to be invalid, the parties nevertheless
        agree that the court should endeavour to give effect to the parties'
        intentions as reflected in the provision, and the other provisions of
        these Terms & Conditions shall remain in full force and effect.
      </p>
      <h5>
        <li>Entire Agreement</li>
      </h5>
      <p>
        This Agreement shall constitute the complete and exclusive agreement
        between the parties with respect to the subject matter hereof,
        superseding any prior agreements, documents and or communications
        regarding such subject matter. This Agreement may only be modified,
        or/and any rights under it waived, by a written document executed by the
        Company only. The right to change vary or amend, however exclusively
        rests with the Company.
      </p>
      <h5>
        <li>Governing Law and Jurisdiction</li>
      </h5>
      <ol type="1">
        <li>
          It is clarified that there is no agency or/and partnership or/and
          joint venture or/and employee-employer or/and franchiser-franchisee
          relationship between Company and any User of the Service.
        </li>
        <li>
          The User agrees that regardless of any statute or law to the contrary,
          any claim or cause of action arising out of or related to use of the
          Service or the Terms & Conditions must be filed within 30 days after
          such claim or cause of action arose or be forever barred.
        </li>
        <li>
          All information of whatsoever nature received from the user is in good
          faith and is bona fide. The information is believed to be true/
          correct and complies with the laws of the land.
        </li>
        <li>
          This Agreement and any dispute or matter arising from incidental use
          of bestpropertiesmohali.com is governed by the laws of India and the User and
          bestpropertiesmohali.com hereby submit to the exclusive jurisdiction of the
          courts at Delhi, India.{" "}
        </li>
      </ol>
      <h5>
        <li>Acknowledgment and Acceptance of Terms and Conditions</li>
      </h5>
      <p>
        The terms and conditions appearing here above consists of the entire
        agreement between the Party/User (as defined above ) and the Company (as
        defined above ) and replaces all previous arrangements/schedules between
        the parties regarding the subject matter contained herein above. By
        completing the registration process and/or checking the "I have read and
        accept the Terms of Use" box, the User is indicating his/her acceptance
        to the agreement and to be bound by all of the terms and conditions of
        the Company as appearing hereinabove.
      </p>
      <br></br>The terms and conditions appearing here above consists of the
      entire agreement between the Party/User (as defined above ) and the
      Company (as defined above ) and replaces all previous
      arrangements/schedules between the parties regarding the subject matter
      contained herein above. By completing the registration process and/or
      checking the "I have read and accept the Terms of Use" box, the User is
      indicating his/her acceptance to the agreement and to be bound by all of
      the terms and conditions of the Company as appearing hereinabove.
      <h5>
        <li>Privacy Policy</li>
      </h5>
      <ol type="1">
        <li>
          bestpropertiesmohali.com respects the privacy of its users and is committed to
          its protection. Click here to read the Privacy Policy.
        </li>
        <li>
          Company through its various advertising campaigns collects information
          about the users. This information is voluntarily provided by the user
          and is collected in the database of bestpropertiesmohali.com. The information
          so collected in the database through these campaigns refers to the
          property details, email address and names of the user. bestpropertiesmohali.com
          uses third-party advertising companies to display/serve their ads on
          various other internet sites for reaching out to its prospective
          users/buyers/sellers. The data collected is for the exclusive use of
          bestpropertiesmohali.com and Company reserves its right to allow access to its
          clients for the purposes of purchase and disposing of properties only,
          and any unauthorized use or sharing of information by any third party
          shall invite appropriate legal action by Company against the erring
          party, including indemnification for third party claims for damages.
        </li>
      </ol>
      <h5>
        <li>PropWorth Disclaimer</li>
      </h5>
      <p>
        The information and opinions available on this website/App are broad
        guidelines for general information only. They are solely intended to
        provide information of general nature of the subject matter. The
        material on this website/App is not and should not be regarded as legal,
        financial or real estate advice. Every effort is made to ensure that the
        material is accurate and up to date. However, we do not guarantee or
        warrant the accuracy, completeness, or currency of the information
        provided. User should make own inquiries and obtain independent
        professional advice before making any legal, financial or real estate
        decisions. bestpropertiesmohali.com is not liable for any loss, special, indirect
        or consequential damage, cost or expenses incurred or which may arise,
        negligence or other tortuous action for any reason whatsoever by any
        person using or relying on information in this product. Measurements,
        prices and locations are approximate and no responsibility is taken for
        any error, omission or misunderstanding in these particulars.
      </p>
      <h5>
        <li>Complimentary Times Prime Lite Subscription (Quarterly)</li>
      </h5>
      <ol type="1">
        <li>
          This offer entitles the eligible Users to get complimentary Times
          Prime Lite Subscription for 90 days. To be eligible the user for the
          said subscription, the user has to have an Indian mobile connection.
          This service is ONLY available with the packs as advertised on the
          Company website.
        </li>
        <li>
          The Users who receive the complimentary Times Prime Subscription will
          also be subject to the terms and conditions governing the subscription
          accessible at https://www.timesprime.com/terms-and-conditions. This
          offer is being extended for a limited period by Times Internet
          Limited.
        </li>

        <li>
          Your purchase, access or use of such service is solely between you and
          Times Internet Limited. The Company does not have any control or does
          not determine or advise or in any way involve itself in the offering
          or acceptance of such commercial/contractual terms between the users
          and Time Internet Limited.
        </li>

        <li>
          In case for some reason we are unable to activate the subscription for
          a user , the user will receive a free boost for their property through
          the helpdesk team
        </li>
      </ol>
      <h5>
        <li>Legal Metrology Disclaimer</li>
      </h5>
      <p>
        User(s) are expressly required to indicate property area in standard
        unit of measurement as per Legal Metrology Act, 2009 i.e. base unit of
        length shall be meter. User(s) agree to the said Law. The Company shall
        not be liable for any infringement of said Law by the User(s).
      </p>
      <h5>
        <li>RERA Disclaimer</li>
      </h5>
      <p>
        Project developers or builders and Real Estate Agents, are required to
        comply with rules, regulations and guidelines of RERA Act 2016 and
        obtain necessary registration under the said Act. It is mandatory for
        Project developers or builders and Real Estate Agents to disclose on the
        Site, all material and requisite information as required under the Act.
        We do not guarantee that Project(s) and Real Estate Agents have
        registered under the Act or are compliant with the same or not. In no
        event will the Company be liable for any claim made by the Users
        including seeking cancellation of services for any of the inaccuracies
        in the information provided in this Site.<br></br>
        It is recommended and advised to refer to respective RERA website(s) to
        see and obtain complete information with respect to any Project/Property
        (which is required to be registered under RERA Act 2016) developed,
        built by a Developer/ Builder and/or initiated /referred to by a Real
        Estate Agent, before making any Buy/Sell or any other decisions.
        <br></br>
        bestpropertiesmohali makes no representations or warranties of any kind, express
        or implied, about the completeness, accuracy, reliability or suitability
        with respect to the information, services or related information
        provided by Developer/Builder/Real Estate Agent/Promoter on its Site
        with respect to any property or project. Fot the sake of clarity, it is
        mentioned that bestpropertiesmohali itself does not operate in the capacity of a
        Real Estate Agent under RERA. bestpropertiesmohali has no control over completion
        or success of any real estate transaction and therefore falls outside
        the scope of RERA.
      </p>
      <h5>
        <li>Rent Agreement Disclaimer</li>
      </h5>
      <ol type="1">
        <li>
          "User" or "Tenant" means a company, partnership, proprietorship or any
          individual person who is searching for a property on rent and is
          willingly submitting their information and details on Company's portal
          in connection with the same.
        </li>

        <li>
          Company is an online portal and acts only as a facilitator between the
          User/tenant and landlord and does not act as an agent of any of the
          parties (User and landlord). The transaction between the User and the
          Company is on principal to principal basis.
        </li>

        <li>
          All confidential information (including name, e-mail address, etc.)
          voluntarily revealed by the User is done at the sole discretion and
          risk of the User. The user authorises Company and any of its partners
          / vendors who may facilitate in the process of procurement and
          creation of the rent agreement to contact him/her via phone, email or
          WhatsApp. The use of User information is subject to the terms of the
          Company's Privacy Policy. Users hereby agree that the Company reserves
          the right to change these terms and conditions at any point of time
          without notifying the User.
        </li>

        <li>
          The User in evaluating the appropriate stamp duty for lease deed /
          rent agreement must seek independent legal advice. The User is
          responsible for the execution of any lease deed / rent agreement,
          payment of stamp duty and is obligated to follow the applicable laws
          of the States in India where the property is situated in connection
          with the same. Company is not providing any opinion on the applicable
          laws on the lease deed / rent agreement, and is not under any
          circumstances, directly or indirectly responsible or liable for the
          advice and assistance provided by any third party including lawyer and
          service provider to the User or to the landlord.
        </li>

        <li>
          Company is not, directly or indirectly, selling stamp papers to any
          person but is only facilitating the completion of the transaction
        </li>

        <li>
          Company has not verified or have given any opinion as to the title of
          the owner of the property and/or condition of the property and User
          has approached the Company only after its own verification and due
          diligence as to the title of the property and/or condition of the
          Property.
        </li>

        <li>
          User and landlord shall be responsible for any tax/duty/liability from
          any concerned authority related to the transaction between User /
          tenant and landlord, and the User shall indemnify the Company in case
          of any liability occurring in consequence of the same.
        </li>

        <li>
          Company shall not be held responsible and cannot be a Party in case of
          any dispute between the User/tenant and landlord and the User shall
          indemnify the Company for any claim / liability on Company in
          consequence of any such dispute between User and the landlord.
        </li>

        <li>
          Cancellation & Refunds: Orders once placed cannot be
          cancelled/refunded. In rare instances of the service not being
          fulfilled within a reasonable amount of time, the Company shall assess
          on a case-to-case basis and provide the best resolution possible, with
          no liability except refund of amount paid by the User, if necessary.
        </li>
      </ol>
      <h5>
        <li>Home Loans</li>
      </h5>
      <ol type="1">
        <li>
          User / customer hereby agrees to accept these terms and conditions and
          authorize bestpropertiesmohali, hereafter referred to as "Company", (through
          its representatives) and Financial Institutions (through their
          representatives) to contact the User (through call / phone, email,
          sms, other online mode, display, notifications) if the User has opted
          for Home Loan service through the company portal or telecalling or any
          other means.
        </li>

        <li>
          Loan sanction / disbursal process, decision and timelines is at the
          sole discretion of the financial institutions to whom the application
          is submitted. The company is involved in the process as a third-party,
          to facilitate collection and submission of relevant documents from the
          User to the financial institution, and any process support and
          advisory thereof, and shall provide these services to the User on a
          best efforts basis. If the loan is not sanctioned / approved and / or
          disbursed, or if there is a delay in the process by the Financial
          Institution, even after recommendation made by the company based on
          the information / details shared by the User, the company shall not be
          responsible or liable for the same, including refund of processing fee
          or any other form of fees or payment, if paid by the User to the
          Financial Institution.
        </li>

        <li>
          All the rates of interest / EMI / tenures / fees / eligibility /
          offers etc. as displayed on the company portal are indicative only,
          and the actual rates of interest / EMI / tenure / fee / eligibility /
          offers, etc. offered by the Financial Institutions shall depend on
          their assessment of the Users' profile and may differ for different
          Users.
        </li>

        <li>
          The company is neither providing confirmation nor giving any guarantee
          to the sanction of loan or quality of services to be rendered by the
          Financial Institutions.
        </li>

        <li>
          The calculations shown in calculators are approximate values and are
          only for self help and planning and the company does not guarantee the
          accuracy of the calculations, whereas the actual eligibility, EMI,
          rate of interests, payment schedule, offers etc. will depend on the
          Financial Institutions assessment of the applicants' profile.
        </li>

        <li>
          The User understands and acknowledges that the Company does not
          collect any form of payment, in cash or any other mode, for home loan
          services from the User. Any payments, where applicable, are required
          to be made directly to the financial institutions and not to the
          Company representative, as processing fees or any other charges by way
          of Cheque in favour of relevant Financial Institution. The Company
          shall not be liable for any cash / direct payments made to the
          Financial Institution through any of these modes, or to the Company's
          employees despite adverse advisory to the User to this effect.
        </li>

        <li>
          The company shall be under no duty to examine, inquire into, or pass
          upon the validity, effectiveness or correctness of any information, or
          any document furnished pursuant hereto or in connection herewith, and
          company shall be entitled to assume that they are valid, effective and
          correct and that if any information be found untrue, the user's loan
          application can be rejected by the Bank at their Sole discretion
        </li>

        <li>
          All confidential information (including name, mobile number e-mail
          address, property details etc.) voluntarily revealed by the User is
          done at the sole discretion and risk of the User. If such information,
          collected by the third party is misused or results in unsolicited
          messages from such third parties, then such actions are beyond the
          control and liability of the company and the company accepts no
          responsibility or liability whatsoever for such actions.
        </li>

        <li>
          The company could and would share User's information with the
          Financial Institutions and brokers / agents / builders to contact the
          User to fulfil User's requirement and further process.
        </li>

        <li>
          The company shall have the right to retain a copy of information /
          details shared by the User in its system and such information shall be
          subject to the company privacy policy as per the prevailing laws and
          practice. Users hereby agree that the company reserves the right to
          change these terms and conditions at its sole discretion at any point
          of time without notifying the User.
        </li>

        <li>
          The company is not responsible to mediate between User and the
          Financial Institutions in case of any dispute between them and the
          company shall not be held responsible and / or cannot be a Party in
          case of any dispute between the User and the Financial Institutions.
          The User shall indemnify the company in case the company suffers any
          loss/liability in consequence of any dispute between User and the
          Financial Institutions.
        </li>

        <li>
          All the information related to Financial Institutions and their
          offerings have been gathered directly and / or from public sources and
          are subject to change at any time without prior notice. Users shall be
          responsible to verify such information directly from the Financial
          Institutions and satisfy themselves before entering into any financial
          transaction or agreement.
        </li>

        <li>
          Third Party Offers and promotions (in any form whatsoever) displayed
          on the company portal are intended for information and marketing
          purposes only and, whilst displayed in good faith, the company shall
          not in any circumstances accept responsibility for their accuracy and
          validity. The company is not responsible for examining or evaluating
          the accuracy of these offerings, and we do not warrant or endorse the
          offerings, promotions of any of the third Party service Providers, or
          the content of their websites. It is the responsibility of the User to
          satisfy himself / herself as to the authenticity of any offers
          displayed on the company portal.
        </li>

        <li>
          Fulfilment services including picking up the information / details /
          documents from the User and submitting to the financial institutions
          are available only in limited cities and for limited User profiles,
          and such services can be changed, suspended, or be made temporarily
          unavailable without notice.
        </li>

        <li>
          The home loans are governed by the regulations and policies laid down
          by the Reserve Bank of India which may change from time to time. The
          User is advised to keep itself abreast of all developments in the
          arena of home loans. bestpropertiesmohali will not be liable for any act or
          omission on the part of the User or the financial institution.
        </li>

        <li>
          These terms and conditions are equally applicable to (i) home loans,
          (ii) loan against property, (iii) balance transfer of home loans and
          loan against property and (iv) top up loans.
        </li>
      </ol>
      <h5>
        <li>PG Fulfilment</li>
      </h5>
      <ol type="1">
        <li>
          bestpropertiesmohali Realty Services Limited ("Company") is an online portal
          and acts only as a connector between the User and PG Owner and not
          acting as an agent of any of the Party. The transaction between the
          User and the Company is on principal to principal basis.
        </li>

        <li>
          User / Tenant hereby consents and agrees to stay in the PG selected by
          him / her for a minimum period of 3 months.
        </li>

        <li>
          All confidential information (including name, e-mail address etc.)
          voluntarily revealed by the User is done at the sole discretion and
          risk of the User. If such information, collected by the third party is
          misused or results in unsolicited messages from such third parties,
          then such actions are beyond the use beyond the control and liability
          of the Company and the company accepts no responsibility or liability
          whatsoever for such actions.
        </li>

        <li>
          Company is not responsible and not providing confirmation to the
          quality of PG and Services to be rendered by PG Owner, and
          accordingly, the User shall ensure and satisfy himself/ herself based
          on the physical verification and direct discussion and assurances as
          may be given by the PG Owner.
        </li>

        <li>
          Company is not responsible to mediate between User and PG Owner for
          setting out the terms and conditions of the rent agreement as to be
          executed between the User and PG Owner.
        </li>

        <li>
          Company has not verified or have given any opinion as to the title of
          the owner of the property and/or condition of the property and
          User/Customer should proceed further only after its own verification
          and due diligence as to the title of the property and/or condition of
          the Property.
        </li>

        <li>
          Company shall not be held responsible and cannot be a Party in case of
          any dispute between the User and PG Owner and the User/Customer shall
          indemnify the Company in case the Company suffer any loss/liability in
          this regard.
        </li>

        <li>
          Company will not be responsible in case of any issues/discrepancies
          with the Services provided by PG Owner or any Lawyer or any other
          service provider.
        </li>

        <li>
          User / PG Owner shall be responsible for any tax/duty/liability from
          any concerned authority related to the transaction and the
          user/customer shall indemnify the Company in case the Company suffers
          any loss/liability in this regard.
        </li>

        <li>
          Company shall reserve the exclusive right to cancel the booking,
          cancellation charges payable to the User shall be at the applicable
          rates laid down in the cancellation and refund policy.
        </li>

        <li>
          User hereby agrees that the Company reserves the right to change these
          terms and conditions at any point of time without notifying the User.
        </li>

        <li>
          PG Fulfillment proposition is only valid for PGs tagged as "MB
          Fulfilled" and this is only applicable to Delhi/NCR.
        </li>
      </ol>
      <h5>
        <li>Tenant Verification</li>
      </h5>
      <ol type="1">
        <li>
          bestpropertiesmohali Realty Services Limited shall hereinafter refer to as the{" "}
          <b>"Company".</b>
        </li>

        <li>
          "Service Provider" means a company, partnership, proprietorship or any
          individual person engaged in the business of providing tenant
          verification services and has agreed to provide such services to the
          User (defined in the following clause) based on the details of the
          User provided to it by the Company.
        </li>

        <li>
          "User" means a company, partnership, proprietorship or any individual
          person who is purchasing the services of a Service Provider for tenant
          verification services on Company's portal and is willingly submitting
          their or their tenant's information to the Service Provider in
          connection with the same.
        </li>
        <li>
          Company is an online portal and acts only as a connector between the
          User and Service Provider for tenant verification services and does
          not act as an agent of any of the parties (User and Service
          Provider"). The transaction between the User and the Company is on
          principal to principal basis.
        </li>
        <li>
          All confidential information (including name, e-mail address etc.)
          voluntarily revealed by the User is done at the sole discretion and
          risk of the User. User agrees for the Service Provider or their
          representatives to contact the User via phone, email or Whatsapp. Use
          of User's information is subject to the terms of Company's Privacy
          Policy. User hereby agree that the Company reserves the right to
          change these terms and conditions at any point of time without
          notifying to the User.
        </li>
        <li>
          Company carefully screens its Service Providers and onboards only
          those who have an impeccable service record in the industry. The
          Company continues and will continue to take utmost care in on-boarding
          Service Providers; however, Company can't be held liable for any
          service quality guarantees.
        </li>
        <li>
          User shall ensure and satisfy themselves to the information / report
          received from Service Provider and if there are any issues or
          shortcoming in such information / report, the User can address it
          directly with the Service Provider, and the Company shall also make
          best efforts to resolve the User concerns. However, the final
          responsibility to addressing these concerns rests with the Service
          Provider. shall initiate direct discussion and get the services
          performed by the Service Provider as per User's requirement. Company
          is not responsible to mediate between User and the Service Provider
          for the quality and performance of services. However, wherever
          possible, the Company will make its best effort to get the issue
          resolved. Company's endeavour is to offer a great experience for the
          User. However, due to the nature of the services advertised, the
          Company cannot be held liable for any quality grievances.
        </li>
        <li>
          Consent of tenant is required to facilitate the verification. Company
          is not responsible to get consent from the tenant for initiating the
          service.
        </li>
        <li>
          Company is neither verifying nor giving any opinion on the character
          or credibility of the tenant. The Company is only facilitating the
          tenant verification service through a Service Provider.
        </li>
        <li>
          Company shall not be held responsible and cannot be a Party in case of
          any dispute between the User and the Service Provider and the User
          shall indemnify the Company for any claim / liability on Company in
          consequence of any such dispute between User and the Service Provider.
        </li>
        <li>Cancellation and Refunds policy:</li>
      </ol>
      <ol type="a">
        <li>
          Tenant verification package is valid for 90 days only. The User can
          contact the Company at any time during this tenure through the details
          received in the order confirmation email for consumption of the
          package.
        </li>

        <li>
          Any cancellation request shall be eligible for 100% refund to the User
          from the Company, if made (i) prior to the sharing of tenant details
          by the User with the Company, and (ii) within 30 days from the date of
          purchase of a package by the User.
        </li>

        <li>
          Any cancellation request shall not be eligible for any refund to the
          User from the Company, if made (i) after sharing of tenant details by
          the User with the Company, and (ii) after 30 days from the date of
          purchase of a package by the User.
        </li>

        <li>
          In rare instances when the service may not be provided within a
          reasonable time or there being obvious lapses in the quality of
          service delivery, the Company will assess the case and provide the
          best possible resolution, with no liability except refund of amount
          paid by the User, if necessary.
        </li>
      </ol>
      <h5>
        <li>Home Services</li>
      </h5>
      <ol>
        <li>
          bestpropertiesmohali Realty Services Limited shall hereinafter refer to as the{" "}
          <b>"Company".</b>
        </li>
        <li>
          "Service Provider" means a company, partnership, proprietorship or any
          individual person engaged in the business of providing Home Services
          (pest control, cleaning, sanitisation etc.) and has agreed to provide
          such services to the User (defined in the following clause) based on
          the details of the User provided to it by the Company.
        </li>
        <li>
          "User" means a company, partnership, proprietorship or any individual
          person who is purchasing the services of a Service Provider for Home
          Services (pest control, cleaning, sanitisation etc.) on Company's
          portal and is willingly submitting their information and details on
          Company's portal in connection with the same.
        </li>
        <li>
          Company is an online portal and acts only as a connector between the
          User and Service Provider for Home Services (pest control, cleaning,
          sanitisation etc.) and does not act as an agent of any of the parties
          (User and Service Provider"). The transaction between the User and the
          Company is on principal to principal basis.
        </li>
        <li>
          All confidential information (including name, e-mail address, physical
          address etc.) voluntarily revealed by the User is done at the sole
          discretion and risk of the User. User agrees for the Service Provider
          or their representatives to contact the User via phone, email or
          Whatsapp. Use of User's information is subject to the terms of
          Company's Privacy Policy. User hereby agree that the Company reserves
          the right to change these terms and conditions at any point of time
          without notifying to the User.
        </li>
        <li>
          The services are also governed by the terms and conditions of the
          specific Service Provider for whose services the order is placed. The
          Service Providers' Terms and Conditions are available here for
          UrbanCompany (https://www.urbancompany.com/terms), Nakoda DCS
          (https://www.nakodadcs.com/Terms-conditions.php) and HiCare
          (https://hicare.in/terms-and-condition)
        </li>
        <li>
          Company carefully screens its Service Providers and onboards only
          those who have an impeccable service record in the industry. The
          Company continues and will continue to take utmost care in on-boarding
          Service Providers, however, Company can't be held liable for any
          service quality guarantees.
        </li>
        <li>
          User shall ensure to satisfy themselves with the service provided by
          the Service Provider. If there are any issues or shortcomings in
          service delivery quality, the User can address it directly with the
          Service Provider, and the Company shall also make best efforts to
          resolve the User concerns. However, the final responsibility to
          addressing these concerns rests with the Service Provider. Company's
          endeavour is to offer a great experience for the User. However, due to
          the nature of the services advertised, the Company cannot be held
          liable for any quality grievances.
        </li>
        <li>
          Company shall not be held responsible and cannot be a Party in case of
          any dispute between the User and the Service Provider and the User
          shall indemnify the Company for any claim / liability on Company in
          consequence of any such dispute between User and the Service Provider.
        </li>
        <li>Cancellation and Refunds policy</li>
      </ol>
      <ol type="a">
        <li>
          Any cancellations made 24 hours or earlier prior to the time of
          service shall be eligible for 100% refund to the User from the
          Company.
        </li>
        <li>
          Any cancellation made within 4-24 hours prior to the time of service
          shall be eligible for 50% refund to the User from the Company.
        </li>
        <li>
          In rare instances of lapses in the service delivery quality, the
          Company will assess the case and provide the best resolution possible,
          with no liability except refund of amount paid by the User, if
          necessary.
        </li>
        <li>Rescheduling</li>
      </ol>
      <ol type="a">
        <li>Any Service Request can be rescheduled only once</li>

        <li>
          Any reschedule request placed 12 hours or prior to the service
          delivery time will be done without any fee
        </li>

        <li>
          Any Reschedule request placed within 4-12 hours of the service
          delivery time may attract a rescheduling fee
        </li>
        <li>
          In rare instance of 'no-show' by Service Partner will be eligible for
          a 100% refund or a reschedule at no extra fee
        </li>
      </ol>
      <h5>
        <li>Property Inspection</li>
      </h5>
      <ol type="1">
        <li>
          bestpropertiesmohali Realty Services Limited shall hereinafter refer to as the{" "}
          <b>"Company".</b>
        </li>
        <li>
          "Service Provider" means a company, partnership, proprietorship or any
          individual person engaged in the business of providing property
          inspection services and has agreed to provide such services to the
          User (defined in the following clause) based on the details of the
          User provided to it by the Company.
        </li>
        <li>
          "User" means a company, partnership, proprietorship or any individual
          person who is purchasing the services of a Service Provider for
          property inspection services on Company's portal and is willingly
          submitting their information and details on Company's portal in
          connection with the same.
        </li>
        <li>
          Company is an online portal and acts only as a connector between the
          User and Service Provider for property inspection services and does
          not act as an agent of any of the parties (User and Service
          Provider"). The transaction between the User and the Company is on
          principal to principal basis.
        </li>
        <li>
          All confidential information (including name, e-mail address, property
          address etc.) voluntarily revealed by the User is done at the sole
          discretion and risk of the User. User agrees for the Service Provider
          or their representatives to contact the User via phone, email or
          WhatsApp. Use of User's information is subject to the terms of
          Company's Privacy Policy. User hereby agrees that the Company reserves
          the right to change these terms and conditions at any point of time
          without notifying the User.
        </li>
        <li>
          Company carefully screens its Service Providers and onboards only
          those who have an impeccable service record in the industry. The
          Company continues and will continue to take utmost care in on-boarding
          Service Providers; however, Company can't be held liable for any
          service quality guarantees.
        </li>
        <li>
          User shall ensure to satisfy themselves with the information / report
          received from Service Provider. If there are any issues or shortcoming
          in such information / report, the User can address it directly with
          the Service Provider, and the Company shall also make best efforts to
          resolve the User concerns. However, the final responsibility to
          addressing these concerns rests with the Service Provider. Company's
          endeavour is to offer a great experience for the User. However, due to
          the nature of the services advertised, the Company cannot be held
          liable for any quality grievances.
        </li>
        <li>
          Company shall not be held responsible and cannot be a Party in case of
          any dispute between the User and the Service Provider and the User
          shall indemnify the Company for any claim / liability on Company in
          consequence of any such dispute between User and the Service Provider.
        </li>

        <li>Cancellation and Refunds policy</li>
        <ol type="a">
          <li>
            Any cancellations or rescheduling made 48 hours (two days) or
            earlier prior to the date of service day shall be eligible for 100%
            refund to the User from the Company.
          </li>

          <li>
            Any cancellation or rescheduling made within 24 hours (one day)
            prior to the date of service (excluding the day of service) shall be
            eligible for 50% refund to the User from the Company.
          </li>

          <li>
            Any cancellations made on the day of the visit or after the visit
            has been concluded or report has been shared will not be eligible
            for any refund to the User from the Company.
          </li>
          <li>
            In rare instances of the service not being fulfilled within a
            reasonable amount of time or there being obvious lapses in the
            service delivery quality, the Company will assess the case and
            provide the best resolution possible, with no liability except
            refund of amount paid by the User, if necessary.
          </li>
        </ol>
      </ol>
      <h5>
        <li>Vastu</li>
      </h5>
      <ol type="1">
        <li>
          bestpropertiesmohali Realty Services Limited shall hereinafter refer to as the{" "}
          <b>"Company".</b>
        </li>
        <li>
          "Service Provider" means a company, partnership, proprietorship or any
          individual person engaged in the business of providing Vastu
          consultancy services for all real estate projects including
          residential, commercial, industrial spaces etc. and has agreed to
          provide such services to the User (defined in the following clause)
          based on the details provided by the user.
        </li>
        <li>
          "User" means a company, partnership, proprietorship or any individual
          person who is searching and purchasing the services of a Service
          Provider for Vastu consultancy services and is willingly submitting
          their information and details on Company's portal in connection with
          the same.
        </li>
        <li>
          Company is an online portal and acts only as a connector between the
          User and Service Provider for Vastu services and does not act as an
          agent of any of the parties (User and Service Provider"). The
          transaction between the User and the Company is on principal to
          principal basis.
        </li>
        <li>
          All confidential information (including name, e-mail address, property
          address etc.) voluntarily revealed by the User is done at the sole
          discretion and risk of the User. User agrees for the Service Provider
          or their representatives to contact the User via phone, email or
          WhatsApp. Use of User's information is subject to the terms of
          Company's Privacy Policy. User hereby agree that the Company reserves
          the right to change these terms and conditions at any point of time
          without notifying to the User.
        </li>
        <li>
          Company carefully screens its Service Providers and onboards only
          those who have an impeccable service record in the industry. The
          Company continues and will continue to take utmost care in on-boarding
          Service Providers; however, Company can't be held liable for any
          service quality guarantees.
        </li>
        <li>
          User shall ensure to satisfy themselves with the information / report
          received from Service Provider. If there are any issues or shortcoming
          in such information / report, the User can address it directly with
          the Service Provider, and the Company shall also make best efforts to
          resolve the User concerns. However, the final responsibility to
          addressing these concerns rests with the Service. Company's endeavour
          is to offer a great experience for the User. However, due to the
          nature of the services advertised, the Company cannot be held liable
          for any quality grievances.
        </li>
        <li>
          Company shall not be held responsible for any desired/undesired
          effects that advice might have on the User.
        </li>
        <li>
          Company shall not be held responsible and cannot be a Party in case of
          any dispute between the User and the Service Provider and the User
          shall indemnify the Company for any claim / liability on Company in
          consequence of any such dispute between User and the Service Provider.
        </li>
        <li>Cancellation and Refunds policy</li>
      </ol>
      <ol type="a">
        <li>
          Any cancellations made prior to sharing of any documents/details (geo
          location, property layout etc.) by the User with the Service Provider
          shall be eligible for 100% refund to the User from the Company.
        </li>

        <li>
          Any cancellation request made after sharing of any documents/details
          (geo location, property layout etc.) is eligible for 50% refund to the
          User from the Company.
        </li>

        <li>
          In rare instances of the service not being fulfilled within a
          reasonable amount of time or there being obvious lapses in the service
          delivery quality, the Company will assess the case and provide the
          best resolution possible, with no liability except refund of amount
          paid by the User, if necessary.
        </li>
      </ol>
      <h5>
        <li>Pay Rent</li>
      </h5>
      <ol type="1">
        <li>
          bestpropertiesmohali Realty Services Limited<b> ("Company")</b> is an online
          portal and acts only as a connector / facilitator between the "User"
          and the "Service Provider" (defined in the following clauses) for
          Legal Services and does not act as an agent of any of the parties. The
          transaction between the User and the Company is on principal to
          principal basis.
        </li>
        <li>
          "User" or "Tenant" means a Company, Partnership, Proprietorship or any
          Individual who is willing to pay rent to its landlord for the rented
          premises using the Company's portal.
        </li>
        <li>
          Use of User's information is subject to the terms of Company's Privacy
          Policy. User must agree to the Privacy Policy before access to the
          website / portal of the Company. User hereby agree that the Company
          reserves the right to change these terms and conditions at any point
          of time without notifying the User.
        </li>

        <li>
          All confidential information (including name, credit/debit card
          details, e-mail address etc.) voluntarily submitted by the User is
          done at the sole discretion and risk of the User. If such information,
          collected by the third party is misused or results in unsolicited
          messages from such third parties, then such actions are beyond the
          control and liability of the Company and the Company accepts no
          responsibility or liability for such actions.
        </li>

        <li>
          The User declares that the use of this Service is done by them only
          for the purpose of rental payment as per the information provided for
          the said landlord / premises with a bonafide rental agreement, and any
          falsification of information or misuse of this service for any purpose
          may attract liabilities / prosecution under applicable laws.
        </li>
        <li>
          Company wishes to advise the User to not disclose their credit card
          information / expiry date / CVV / OTP details to any person; the
          Company never seeks such information from the "User" except through
          automated and secure payment methods, and the Company shall not be
          liable for any such disclosure made by the "User" to anyone even they
          claim to belong to the Company.
        </li>
        <li>
          User shall be responsible to check and verify the landlord's details
          including name, mobile number etc. before making payment of rent on
          Company's portal.
        </li>
        <li>
          Company shall not be held responsible for refund in any case whether
          wrong details of landlord selected by User or wrong details filled and
          submitted by User while making payment of rent on Company's portal.
        </li>
        <li>
          User shall be solely responsible for genuineness of credit / debit
          cards details while making payment of rent on Company's portal and in
          case of any fraud or fake credit / debit card used by User on
          Company's portal, the User shall be solely responsible and liable for
          the same and the User shall indemnify the Company in case of any
          claim, loss or liability occurs in consequence of the same.
        </li>
        <li>
          Company shall not be held responsible or made a party to any dispute
          between the parties (User/tenant and landlord).
        </li>
        <li>
          User shall indemnify the Company in case the Company is made a party
          to the dispute between User/tenant and landlord or incurred any
          loss/liability in consequence of the same
        </li>
        <li>
          User shall be responsible to save and keep safe the receipt of payment
          of rent made by the User on Company's portal.
        </li>
      </ol>
      <h5>
        <li>Legal Services</li>
      </h5>
      <ol type="1">
        <li>
          bestpropertiesmohali Realty Services Limited <b> ("Company")</b> is an online
          portal and acts only as a connector / facilitator between the "User"
          and the "Service Provider" (defined in the following clauses) for
          Legal Services and does not act as an agent of any of the parties. The
          transaction between the User and the Company is on principal to
          principal basis.
        </li>
        <li>
          "Service Provider" means a company, partnership, proprietorship or any
          individual person engaged in the business of providing Legal Services
          and has agreed to provide such services to the User based on the
          details of the User provided to the Service Provider by the Company.
          However, the Service Provider is an independent service provider and
          is not an agent/employee of the Company and/or directly or indirectly
          related to the Company.
        </li>
        <li>
          "User" means an individual, company, partnership or proprietorship who
          is purchasing the Legal Services on Company's portal which will be
          provided by the Service Provider and is willingly submitting their
          information and details on Company's portal in connection with the
          same.
        </li>
        <li>
          All confidential information (including name, e-mail address, property
          address etc.) voluntarily revealed by the User is done at the sole
          discretion and risk of the User. User agrees for the Service Provider
          or their representatives to contact the User via phone, email or
          WhatsApp. Use of User's information is subject to the terms of
          Company's Privacy Policy. User hereby agrees that the Company reserves
          the right to change these terms and conditions at any point of time
          without notifying the User.
        </li>
        <li>
          Company carefully screens its Service Providers and onboards only
          those who have an impeccable service record in the industry. Company
          continues and will continue to take utmost care in on-boarding Service
          Providers and monitoring their service quality as per the agreed
          terms; however, Company cannot be directly held liable for any service
          quality guarantees.
        </li>
        <li>
          User shall ensure to satisfy themselves with the information / report
          received from Service Provider in connection with the Legal Services
          purchased on the Company's portal. If there are any issues or
          shortcoming in such information / report, the User can address it
          directly with the Service Provider, and the Company shall also make
          best efforts to resolve the User concerns. However, the final
          responsibility to addressing these concerns rests with the Service
          Provider. Company's endeavour is to offer a great experience for the
          User. However, due to the nature of the services advertised, the
          Company cannot be directly held liable for any quality grievances.
        </li>
        <li>
          Company shall not be held responsible and cannot be a Party in case of
          any dispute between the User and the Service Provider and the User
          shall indemnify the Company for any claim / liability on Company in
          consequence of any such dispute between User and the Service Provider.
        </li>
        <li>
          User agrees to the company's Privacy Policy by purchasing the desired
          package/service from the Service Provider of his choice on the
          Company's portal. User hereby agree that the Company reserves the
          right to change these terms and conditions at any point of time
          without notifying the User.
        </li>
        <li>Cancellation and Refunds policy</li>
      </ol>
      <ol type="a">
        <li>
          Any cancellations made by the User prior to the start of verification
          service, where partial/some of the documentation/ information (as
          required by the Service Provider for providing legal services) is
          provided, shall be eligible for 50% refund.
        </li>

        <li>
          Any cancellation made by the User prior to the start of verification
          service, where none of the documentation/ information (as required by
          the Service Provider for providing legal services) has been provided,
          shall be eligible for 100% refund.
        </li>

        <li>
          Any cancellation made by the User where all documentation/ information
          (as required by the Service Provider for providing legal services) has
          been provided, shall not be eligible for any refund.
        </li>

        <li>
          In rare instances of the service not being fulfilled within a
          reasonable amount of time or there being obvious lapses in the service
          delivery quality, the Company will assess the case and provide the
          best resolution possible, with no liability except refund of amount
          paid by the User, if necessary.
        </li>
      </ol>
      <h5>
        <li>Packers & Movers</li>
      </h5>
      <ol type="1">
        <li>
          bestpropertiesmohali Realty Services Limited ("Company/We") have merely
          partnered with Service Provider in order to facilitate extended
          services to the Users purchasing Packers and Movers Services. The
          services provided by the Service Provider is provided by it in their
          capacity as an independent service provider. We do not provide the
          services in any capacity whatsoever. We do not endorse the services of
          the Service Provider.
        </li>
        <li>
          "Service Provider" means a company, partnership, proprietorship or any
          individual person engaged in the business of providing Packers and
          Movers Services and has agreed to provide such services to the User
          (defined in the following clause) based on the details of the User
          provided by it to the Company.
        </li>
        <li>
          "User" means a company, partnership, proprietorship or any individual
          person who is purchasing the services of a Service Provider for
          Packers and Movers Services via the Company portal. In connection with
          the same, the User has willingly provided their information and
          details on Company's portal or over email/telephonic exchange.
        </li>
        <li>
          We do not act as an agent of any of the parties (User and Service
          Provider). The transaction between the User and the Company is on
          principal-to-principal basis.
        </li>
        <li>
          All confidential information (including name, e-mail address, physical
          address etc.) voluntarily revealed by the User is done at the sole
          discretion of the User. User agrees that the Company, Service
          Provider(s) and/or their respective representatives can contact the
          User via phone, email or Whatsapp. Use of User's information is
          subject to the terms of Company's Privacy Policy. User hereby agrees
          that the Company reserves the right to change these terms and
          conditions at any point of time without notifying the User.
        </li>
        <li>
          In addition to the terms and conditions of the Company, the services
          are governed by the specific terms and conditions of the Service
          Provider for whose services the order is placed. The Service
          Providers' Terms and Conditions will be informed to the User before
          placing the order.
        </li>
        <li>
          We shall not be liable for any act(s)/omission(s) of the Service
          Provider and/or any defect/deficiency in provision of the services
          including their refusal to provide the services. Any third-party
          facility/offer(s)/service(s)/product(s) shall be subject to the terms
          and conditions of such third party which shall be applicable to the
          User. We do not endorse, make no representation and shall have no
          liability or obligation whatsoever in relation to such third-party
          offer(s).
        </li>
        <li>
          Users are advised to do proper due diligence before engaging
          themselves with any Service Provider. bestpropertiesmohali does not guarantee
          or warrant the genuineness or credibility of the credentials or
          quality of work proposed or promised by the Service Provider or their
          agents or associates. In the event of any dispute, bestpropertiesmohali Realty
          Services Limited shall have no responsibility towards either party.
        </li>
        <li>
          All such third-party offer(s) shall be availed by User at their own
          risk and responsibility. We shall not be liable for any payment
          obligation in relation to such third-party offer(s), which shall be
          the User's responsibility.
        </li>
        <li>
          We reserve the sole right to modify, amend, change, refuse or revoke
          services, any offer(s) including third party offer(s) herein at any
          time without assigning any reason and without any liability and
          notice.
        </li>
        <li>
          Any disputes arising pursuant to these terms and conditions are
          subject to the exclusive jurisdiction of courts in New Delhi.
        </li>
        <li>
          <b>Cancellation and Refunds policy:</b>
        </li>
        <ol type="a">
          <li>
            Any cancellations made up to 48 hours prior to service delivery time
            shall be eligible for 100% refund of booking amount to the User from
            the Company.
          </li>
          <li>
            Any cancellation made within 0-48 hours of the service delivery time
            shall not be eligible for any refund of booking amount to the User
            from the Company.
          </li>
        </ol>
        <li>
          <b>Rescheduling</b>
        </li>

        <ol type="a">
          <li>A reschedule request can be placed only once.</li>
          <li>
            A request to reschedule the services if placed within 0-4 hours of
            the service delivery time will attract an appropriate rescheduling
            fee.
          </li>
          <li>
            A request to reschedule the services if placed within 4-12 hours of
            the service delivery time may attract a rescheduling fee.
          </li>
          <li>
            A request to reschedule the services if placed up to 12 hours prior
            to the service delivery time will be accepted without any extra fee.
          </li>
          <li>
            In a rare instance of 'no-show' by Service Provider, the User will
            be eligible for a 100% refund of booking amount or a reschedule at
            no extra fee.
          </li>
        </ol>

        <li>
          <b>Insurance</b>
        </li>
        <p>
          Insurance charges are based on the value of goods to be moved and are
          over and above the quotation shared with the User, unless mentioned
          otherwise.
        </p>

        <li>
          <b>Others</b>
        </li>
        <p>
          Quotation amount is subject to the item/article list, movement date,
          source and destination address as provided by the User. Any change in
          such parameters may cause the quotation amount to change. The User
          agrees to pay the difference in cases of variation from the actual
          details provided by the User.
        </p>
        <li>
          Promotions/discounts offered as part of the Packers and Movers Service
          are subject to bestpropertiesmohali T&Cs and the User hereby agrees that the
          Company reserves the right to change these terms and conditions at any
          point of time without notifying the User.
        </li>

        <li>
          <b>Dispute Resolution</b>
        </li>
        <p>
          If any dispute arises between a user/users and bestpropertiesmohali arising out
          of use of the website or thereafter, in connection with the validity,
          interpretation, implementation or alleged breach of any provision of
          these terms and conditions, the dispute shall be referred to a sole
          Arbitrator who shall be an independent and neutral third party.
          Decision of the Arbitrator shall be final and binding on both the
          parties to the dispute. The place of arbitration shall be Delhi. The
          Arbitration & Conciliation Act, 1996, shall govern the arbitration
          proceedings.
        </p>
      </ol>
      <h5>
        <li>Guaranteed Tenant Plan</li>
      </h5>
      <ol type="1">
        <li>
          bestpropertiesmohali Realty Services Limited (Hereinafter refer to as,{" "}
          <b>"Company"</b>) is an online portal and acts only as a facilitator
          between the Property Owner, Potential Tenant and Service Provider/
          Broker and does not act as an agent of any of the party (Tenant,
          Landlord/ Owner, and Broker). The transaction between the party(s) is
          on principal-to-principal basis.
        </li>
        <li>
          <b>Landlord/ User/Owner</b> means a Company, Partnership,
          Proprietorship, or any individual who is rightful and legal owner of
          property and willing to lease out its property/ premises by using the
          Company's portal.
        </li>
        <li>
          <b>Potential Tenant</b> means a Company, Partnership, Proprietorship,
          or any individual who is willing to hold a tenancy in Owner's
          property(s)/premise(s).
        </li>
        <li>
          <b>Service Provider/ Broker</b> means a Company, Partnership,
          Proprietorship, or any individual person who is engaged in the
          business of providing assistance/ services to owner/ Landlord to find
          the tenant for renting out the Owner's premises and enrolled himself
          as a Service Provider of Company for providing such services as per
          the terms agreed in mandate.
        </li>
        <li>
          <b>Mandate</b> means the agreed terms and the exclusive right(s) is
          given to Company by the Owner for the purpose to rent out the Owner's
          Property.
        </li>
        <li>
          The Company offer a <b>Guaranteed Tenant Plan(In Short "GTP Plan")</b>{" "}
          for the property owner(s) seeking tenant for their property posted
          over the Company's website i.e. www.bestpropertiesmohali.com as per the terms &
          conditions outlined below
        </li>
        <ol type="a">
          <li>
            The benefit under this plan can be extended to only those Property
            Owner(s) who subscribed the{" "}
            <b>
              "Guaranteed Tenant Plan" online through the company official
              website or through mobile app.
            </b>{" "}
            The property listing made by the respective owner, prior subscribing
            the said GTP Plan, over the Company's website shall be deactivated
            during the duration of GTP Plan.
          </li>
          <li>
            The Owner is not required to pay any amount as a commission/
            brokerage to the Service Provider/ Broker under this GTP Plan.
          </li>
          <li>
            That the Potential Tenant is to be find by the Service Provider /
            Broker of the Company based on the preferences shared by the
            respective owner(s) at the time of subscribing the GTP Plan.
          </li>
          <li>
            That, as per the said Plan, the Property Owner will get full refund
            against the subscribed package along with bonus amount / some extra
            benefit up to <b>Rs. 7,000/- (Rs. Seven Thousand Only)</b> in the
            event when the Service Provider/ Broker wouldn't be able to be
            leased out the said property within 28 days from the date on which
            the said GTP Plan is activated by the Owner. The details pertaining
            to terms agreed between the Company and Owner will be shared to the
            Owner on its registered Email Id or WhatsApp (Installed on
            Registered Mobile Number).
          </li>
          <li>
            That Property Owner agree to give exclusive right for the duration
            of 28 days to Company and its Service Provider/ broker to find the
            right tenant and rent out the said property as per the agreed terms.
            However, the Owner shall not be entitled to get any refund in the
            event when the said property space is not available for the tenant
            suggested by Service Provider/Broker, or the tenant is finalized by
            the Owner himself during the duration for which Company or its
            Service Provider/ broker get exclusive right.
          </li>
          <li>
            The Owner's right to get the refund shall be forfeited and not
            entitled to get any refund in the event when 2 different tenant
            option, within his preference suggested by the Service
            Provider/Broker, isn't opted by the Owner. However, the Service
            Provider/ Broker may use its best possible efforts to rent out the
            Owner's Property/ Premises throughout the entire duration of the
            subscribed package.
          </li>
          <li>
            If deemed necessary, a formal request may be submitted to the Owner
            for permission to conduct a photoshoot of the property. The request
            shall seek the Owner's cooperation in facilitating the rental of the
            Owner's premises or property.
          </li>
          <li>
            The Owner shall be fully responsible towards the accuracy,
            reliability, authenticity & correctness of the property detail(s)
            listed/posted/ shared by the Owner. However, in the event, if any
            discrepancy is found in the detail(s) then the Owner shall not be
            entitled to get any refund and the amount paid against the
            subscribed package shall be forfeited.
          </li>
          <li>
            The Owner hereby agrees to fully cooperate with the Service
            Provider/broker assigned to them during the site visit of the
            property and provide all relevant details to the potential tenant,
            if necessary. It is important to note that the Owner will not be
            eligible for a refund if the Company observes non-cooperation on
            three separate occasions during the site visit of a potential
            tenant.
          </li>
          <li>
            The Owner agree that he shall be fully responsible and take all
            reasonable and necessary steps, as prescribed by law, towards
            conducting the police verification of tenant.
          </li>
        </ol>

        <li>
          The Said GTP Plan is currently available only in 1 city of India i.e.
          Bengaluru, (Karnataka) India.
        </li>
        <li>
          All confidential information (including name, e-mail address, physical
          address etc.) voluntarily revealed by the Owner is done at the sole
          discretion of the Owner. Owner agrees that the Company, Service
          Provider/ Broker and/or their respective representatives can contact
          the Owner via phone, email or WhatsApp. Use of Owner's information is
          subject to the terms of Company's Privacy Policy. Owner hereby agrees
          that the Company reserves the right to change these terms and
          conditions at any point of time without notifying the Owner.
        </li>
        <li>
          The Company can't held<b> be</b> liable for any service quality
          concern raised by Owner. However, the Company shall make best efforts
          to resolve the Owner Concern, if any, during the duration of the
          subscribed package.
        </li>
        <li>
          The Company is neither verifying nor giving any opinion on the
          character or credibility of the tenant. The Company is only
          facilitating to find out the tenant under the said GTP Plan through
          Service Provider/ Broker.
        </li>
        <li>
          Company shall not be held responsible and cannot be a party in case of
          any dispute arises between the (I) Owner & Service Provider/ Broker,
          (II) Owner & Tenant, and the<b> Owner </b>shall indemnify the Company
          for any claim/ liability on Company in consequences of any such
          dispute between the (I) Owner & Service Provider/ Broker And (II)
          Owner & Tenant.
        </li>
        <li>Cancellation Policy:</li>
      </ol>
      <ol type="a">
        <li>
          Pursuant to the Cancellation Policy, the owner(s), who subscribed the
          current package have the right to cancel their mandate within 5
          Working Days of activation. If such cancellation occurs within this
          period, a complete refund will be issued, with no additional bonuses.
          However, the Company shall not entertain any cancellation request if
          it has identified the Service Provider/Broker and has communicated
          such information to the Owner prior to the Owner's request for
          cancellation.
        </li>
        <li>
          After the expiration of the 5 Working Days period, the Owner(s) shall
          no longer be permitted to cancel their mandate, and the Company shall
          not be obliged to provide any refunds or bonuses thereafter.
        </li>
      </ol>
      <h5>Your Trusted Partner in Property Search</h5>
      <p className="mb-10">
        All trademarks, logos and names are properties of their respective
        owners. All Rights Reserved. © Copyright 2024 bestpropertiesmohali Realty
        Services Limited. Terms of use
      </p>
    </div>
    <BottomBar/>
    </>
  );
}
